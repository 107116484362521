<template>
  <div
    class="view-edit-mode"
    :class="{
      hidden: isDetailPage,
    }"
  >
    <div
      class="position-relative dx-viewport"
      :class="{
        'dx-datepicker-valid': !isInvalid,
        'dx-datepicker-invalid': isInvalid,
      }"
    >
      <DxDateBox
        class="dx-datepicker-container"
        ref="datePicker"
        :value="datePickerValueAsDate"
        type="date"
        :showClearButton="false"
        :disabled="!isEnabled"
        :openOnFieldClick="true"
        :input-attr="inputAttributes"
        :onValueChanged="onValueChanged"
        :useMaskBehavior="true"
        :interval="30"
        applyValueMode="instantly"
      />
      <div class="invalid-tooltip">
        {{
          this.$t(
            "FieldWarnings.TextNotValid",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </div>
    </div>
  </div>
  <div class="view-detail-mode" v-if="isDetailPage">
    <span :id="field.publicId" class="d-flex text-break">{{
      String.isNullOrWhiteSpace(datePickerValueAsString)
        ? $valueNotAvailableSeparator
        : datePickerValueAsString
    }}</span>
  </div>
</template>
<script>
/* https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxDateBox/Configuration/ */
import DxDateBox from "devextreme-vue/date-box";
export default {
  name: "DatePicker",
  data() {
    return {
      inputAttributes: {
        class: `form-control ${
          String.isNullOrWhiteSpace(this.element.valueClassNames)
            ? ""
            : this.element.valueClassNames
        }`,
        id: this.element.id,
        name: this.element.id,
      },
      formatInfo: this.$root.getDateTimeFormat("short"),
      datePickerValueAsDate: null,
      datePickerValueAsString: null,
      isNewPage: this.crudType === "NEW",
      isDetailPage: this.crudType === "DETAIL",
      placeholder: this.field.fieldType.includes("Formula")
        ? this.$t(
            "BaseModelFields.FieldIsCalcForSystem",
            {},
            { locale: this.$store.state.activeLang }
          )
        : this.$t(
            "BaseModelFields.ChooseDate",
            {},
            { locale: this.$store.state.activeLang }
          ),
      oldValue: "",
      isEnabled:
        this.field.isActive &&
        !this.element.isDisabled &&
        !this.field.fieldType.includes("Formula") &&
        !this.field.fieldType.includes("Rollup"),
    };
  },
  components: {
    DxDateBox,
  },
  computed: {
    isInvalid() {
      return (
        (this.element.isRequired || this.field.isRequired) &&
        String.isNullOrWhiteSpace(this.datePickerValueAsDate)
      );
    },
  },
  watch: {
    datePickerValueAsDate() {
      if (String.isNullOrWhiteSpace(this.datePickerValueAsDate)) {
        this.onCleared();
      }
    },
  },
  props: ["element", "field", "value", "crudType", "isCellEdit", "rowData"],
  methods: {
    onValueChanged(e) {
      if (String.isNullOrWhiteSpace(e.event)) return;

      /* UI Change Event Fix */
      this.$root.formItemNewChangeEvent([this.field.publicId]);

      this.datePickerValueAsDate = e.value;

      if (!this.isCellEdit) {
        setTimeout(() => {
          this.$root.calculateTriggerField(this.field);
        }, 1000);
      }
    },
    setValue(isSuccess, date) {
      if (String.isNullOrWhiteSpace(date)) return;

      this.datePickerValueAsDate = this.$moment(
        date,
        this.formatInfo.calendar
      ).format("yyyy-MM-DD");

      if (isSuccess) {
        this.oldValue = date;
        this.datePickerValueAsString = date;
      }
    },
    onCleared() {
      this.$root.childValuesEmpty(this.field.publicId);
    },
  },
  mounted() {
    if (
      !String.isNullOrWhiteSpace(this.value) &&
      this.value !== this.$formulaErrorText
    ) {
      this.datePickerValueAsDate = this.$moment(
        this.value,
        this.formatInfo.calendar
      ).format("yyyy-MM-DD");

      var formatedDate = this.$root.dateFormat(this.value);
      this.datePickerValueAsString = formatedDate;
      this.oldValue = formatedDate;
    }

    if (this.$route.meta.isEditPage && this.isEnabled) {
      this.isEnabled = this.field.isEditable;
    }

    var datePicker = document.getElementById(this.element.id);
    if (!String.isNullOrWhiteSpace(datePicker)) {
      if (this.element.isRequired || this.field.isRequired) {
        datePicker.setAttribute("required", "");
      }

      datePicker.setAttribute("placeholder", this.placeholder);
      datePicker.setAttribute("data-oldvalue", this.oldValue);
    }
  },
};
</script>
