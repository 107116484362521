<template>
  <template v-if="!isCellEdit || isDetailPage">
    <div
      :class="{
        'file-valid': !isRequired || (isRequired && uploadedFiles.length > 0),
        'file-invalid': isRequired && uploadedFiles.length == 0,
      }"
    >
      <input
        type="hidden"
        :id="element.id"
        :name="element.id"
        :value="
          uploadedFiles
            .map(function (x) {
              return x.Id;
            })
            .join(this.$systemSeparator)
        "
        :data-oldvalue="oldValue"
      />
      <input
        type="hidden"
        :id="String.format('FILENAME-{0}', element.id)"
        :name="String.format('FILENAME-{0}', element.id)"
        :data-oldvalue="oldText"
        :value="
          uploadedFiles
            .map(function (x) {
              return x.Name;
            })
            .join(this.$systemSeparator)
        "
      />
      <div class="view-edit-mode" :class="{ hidden: isDetailPage }">
        <div class="position-relative">
          <button
            :disabled="isDisabled"
            type="button"
            class="btn btn-sm btn-light d-flex btn-browse"
            :id="String.format('btn-browse-{0}', field.publicId)"
            :data-bs-target="String.format('#modalFileUpload_{0}', element.id)"
            data-bs-toggle="modal"
          >
            <i class="far fa-folder fs-4" v-if="field.fieldType == 'File'"></i>
            <i class="far fa-image fs-4" v-else></i>
            {{
              $t(
                "BaseModelFields.Browse",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
          <div
            class="invalid-message invalid-tooltip hidden"
            v-if="field.fieldType == 'File'"
          >
            {{
              this.$t(
                "FieldWarnings.FileNotSelected",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </div>
          <div class="invalid-message invalid-tooltip hidden" v-else>
            {{
              this.$t(
                "FieldWarnings.ImageNotSelected",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </div>
        </div>
        <FormFileList
          :uploadedFiles="uploadedFiles"
          :field="field"
          :onlyView="false"
          parentCssClasses="mt-2 ms-0"
          @download="download"
          @removeFile="removeFile"
        />
      </div>
      <div class="view-detail-mode" v-if="isDetailPage">
        <span
          class="d-flex text-break"
          v-if="isDetailPage && String.isNullOrWhiteSpace(orjValue)"
          >{{ this.$valueNotAvailableSeparator }}</span
        >
        <FormFileList
          :field="field"
          :uploadedFiles="uploadedFiles"
          :onlyView="isDetailPage"
          parentCssClasses="mt-0 ms-0"
          @download="download"
          @removeFile="removeFile"
        />
      </div>
    </div>
    <FileUploadModal
      v-if="!isDisabled"
      :field="field"
      :element="element"
      @uploadFileAfterCallMethod="writeFile"
    />
    <ImageViewModal
      :field="field"
      :value="orjText"
      :valueId="orjValue"
      v-if="
        (field.property === 'only_view_on_modal' ||
          field.property === 'only_view_on_detail') &&
        isDetailPage
      "
    />
  </template>
  <template v-else>
    <FileUpload
      ref="fileUpload"
      :field="field"
      :element="element"
      :isCellEdit="isCellEdit"
      :rowData="rowData"
      @uploadFileAfterCallMethod="writeFile"
    />
    <input
      type="hidden"
      :id="element.id"
      :name="element.id"
      :value="
        uploadedFiles
          .map(function (x) {
            return x.Id;
          })
          .join(this.$systemSeparator)
      "
      :data-oldvalue="oldValue"
    />
    <input
      type="hidden"
      :id="String.format('FILENAME-{0}', element.id)"
      :name="String.format('FILENAME-{0}', element.id)"
      :data-oldvalue="oldText"
      :value="
        uploadedFiles
          .map(function (x) {
            return x.Name;
          })
          .join(this.$systemSeparator)
      "
    />
    <div class="view-detail-mode">
      <FormFileList
        :field="field"
        :uploadedFiles="uploadedFiles"
        :onlyView="isDetailPage"
        parentCssClasses="mt-2 ms-0"
        @download="download"
        @removeFile="removeFile"
      />
    </div>
  </template>
</template>
<script>
import $ from "jquery";
import ImageViewModal from "@/components/custom/image-view/ImageViewModal.vue";
import FileUploadModal from "@/components/custom/file-upload/FileUploadModal.vue";
import FormFileList from "@/components/custom/set-pages/FormFileList.vue";
import FileUpload from "@/components/custom/file-upload/FileUpload.vue";
export default {
  name: "FileInput",
  props: [
    "element",
    "field",
    "value",
    "valueId",
    "crudType",
    "isCellEdit",
    "rowData",
  ],
  emits: ["cellFileUpload", "removedFile"],
  components: {
    FileUploadModal,
    FileUpload,
    ImageViewModal,
    FormFileList,
  },
  data() {
    return {
      fileBrowseButtonMinWidth: 100, //px
      isRequired: this.element.isRequired || this.field.isRequired,
      isDetailPage: this.crudType === "DETAIL",
      isDisabled:
        !this.field.isActive ||
        this.element.isDisabled ||
        (this.$route.meta.isEditPage && !this.field.isEditable),
      uploadedFiles: [],
      oldUploadFiles: [],
      oldValue: "",
      oldText: "",
      orjValue: this.valueId,
      orjText: this.value,
      permission: this.$parent.customObjectItemPermissions,
    };
  },
  methods: {
    setValue(isSuccess, value, text) {
      if (this.oldValue == value && this.oldText == text) {
        this.uploadedFiles = this.oldUploadFiles;
        if (
          !String.isNullOrWhiteSpace(value) &&
          this.permission.isReadDocumentsAllowed
        ) {
          if (
            this.field.property === "only_view_on_modal" ||
            this.field.property === "only_view_on_detail"
          ) {
            if (this.field.isMultipleValue) {
              value
                .split(this.$systemSeparator)
                .forEach((documentOrImageId) => {
                  this.$root.previewDocumentOrImage(
                    documentOrImageId,
                    this.oldValue
                  );
                });
            } else {
              this.$root.previewDocumentOrImage(value, this.oldValue);
            }
          }
        }
        return;
      }

      this.uploadedFiles = [];
      if (
        !String.isNullOrWhiteSpace(text) &&
        !String.isNullOrWhiteSpace(value) &&
        value !== this.$valueNotAvailableSeparator
      ) {
        this.orjValue = value;
        this.orjText = text;
        if (this.field.isMultipleValue) {
          value.split(this.$systemSeparator).forEach((v, i) => {
            this.uploadedFiles.push({
              Id: v,
              Name: text.split(this.$systemSeparator)[i],
            });
          });
        } else {
          this.uploadedFiles.push({ Id: value, Name: text });
        }
        this.oldUploadFiles = this.uploadedFiles;
      } else {
        this.orjValue = "";
        this.orjText = "";
      }

      if (isSuccess) {
        if (
          this.isDetailPage &&
          !String.isNullOrWhiteSpace(value) &&
          this.permission.isReadDocumentsAllowed
        ) {
          if (
            this.field.property === "only_view_on_modal" ||
            this.field.property === "only_view_on_detail"
          ) {
            if (this.field.isMultipleValue) {
              value
                .split(this.$systemSeparator)
                .forEach((documentOrImageId) => {
                  this.$root.previewDocumentOrImage(
                    documentOrImageId,
                    this.oldValue,
                    this.field
                  );
                });
            } else {
              this.$root.previewDocumentOrImage(
                value,
                this.oldValue,
                this.field
              );
            }
          }
        }
        this.oldValue = value;
        this.oldText = text;
      }
    },
    removeFile(fileItem) {
      this.uploadedFiles = this.uploadedFiles.filter(function (item) {
        return item.Id !== fileItem.id;
      });
      this.$emit("removedFile", this.uploadedFiles);
    },
    writeFile(obj) {
      if (!this.field.isMultipleValue) {
        this.uploadedFiles = [];
      }
      this.uploadedFiles.push(obj);
      this.$emit("cellFileUpload", obj);
    },
    download(item) {
      this.$root.downloadFile(item);
    },
  },
  mounted() {
    if (
      !String.isNullOrWhiteSpace(this.valueId) &&
      !String.isNullOrWhiteSpace(this.value) &&
      this.value !== this.$valueNotAvailableSeparator
    ) {
      this.orjValue = this.valueId;
      this.orjText = this.value;
      this.oldValue = this.valueId;
      this.oldText = this.value;
      if (this.field.isMultipleValue) {
        this.value.split(this.$systemSeparator).forEach((value, i) => {
          this.uploadedFiles.push({
            Id: this.valueId.split(this.$systemSeparator)[i],
            Name: value,
          });
        });
      } else {
        this.uploadedFiles.push({ Id: this.valueId, Name: this.value });
      }
      this.oldUploadFiles = this.uploadedFiles;
    } else {
      this.orjValue = "";
      this.orjText = "";
      this.oldText = "";
      this.oldValue = "";
    }

    var browseButton = $("#btn-browse-" + this.field.publicId);
    if (browseButton) {
      var browseButtonWidth = browseButton.width();
      browseButton.attr(
        "style",
        browseButtonWidth <= this.fileBrowseButtonMinWidth
          ? "width:100% !important"
          : ""
      );
    }

    if (this.isDetailPage && !String.isNullOrWhiteSpace(this.valueId)) {
      if (this.permission.isReadDocumentsAllowed) {
        if (
          this.field.property === "only_view_on_modal" ||
          this.field.property === "only_view_on_detail"
        ) {
          if (this.field.isMultipleValue) {
            this.valueId
              .split(this.$systemSeparator)
              .forEach((documentOrImageId) => {
                this.$root.previewDocumentOrImage(documentOrImageId);
              });
          } else {
            this.$root.previewDocumentOrImage(this.valueId);
          }
        }
      }
    }
  },
};
</script>
