<template>
  <input
    type="hidden"
    :id="String.format('SELECT-TEXT-{0}', element.id)"
    :name="String.format('SELECT-TEXT-{0}', element.id)"
    :value="selectedId ? selectedText : ''"
    :data-oldvalue="oldText"
  />
  <div class="view-edit-mode" :class="{ hidden: isDetailPage }">
    <div
      :class="{
        'select2-invalid': isInvalid,
        'select2-valid': !isInvalid,
      }"
      class="position-relative"
    >
      <Select2
        :placeholder="placeholder"
        ref="select2"
        :id="element.id"
        :name="element.id"
        :settings="settings"
        :class="elementClasses"
        class="select2-parent-container select2-dependency-item"
        @select="selection($event)"
        :required="isRequired"
      />
      <div class="invalid-message invalid-tooltip hidden">
        {{
          this.$t(
            "FieldWarnings.TextNotValid",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </div>
    </div>
  </div>
</template>
<script>
import Select2 from "vue3-select2-component";
import $ from "jquery";
export default {
  name: "SelectListDependecyItem",
  props: [
    "element",
    "field",
    "value",
    "valueId",
    "crudType",
    "isCellEdit",
    "rowData",
  ],
  data() {
    return {
      isDetailPage: this.crudType === "DETAIL",
      isRequired: this.field.isRequired || this.element.isRequired,
      elementClasses: !String.isNullOrWhiteSpace(this.element.valueClassNames)
        ? this.element.valueClassNames
        : "",
      placeholder: this.field.fieldType.includes("Formula")
        ? this.$t(
            "BaseModelFields.FieldIsCalcForSystem",
            {},
            { locale: this.$store.state.activeLang }
          )
        : this.$t(
            "BaseModelFields.Choose",
            {},
            { locale: this.$store.state.activeLang }
          ),
      selectedText: "",
      selectedId: "",
      oldValue: "",
      oldText: "",
      settings: {
        dropdownParent: this.$root.getSelect2ParentDropdown(),
        language: this.$root.getSelect2Locale(this.$store.state.activeLang),
        theme: "bootstrap-5",
        allowClear: !(
          !this.field.isActive ||
          this.element.isDisabled ||
          this.field.fieldType.includes("Formula") ||
          (this.$route.meta.isEditPage && !this.field.isEditable)
        ),
        closeOnSelect: true,
        maximumSelectionSize: 99,
        minimumInputLength: 0,
        disabled:
          !this.field.isActive ||
          this.element.isDisabled ||
          this.field.fieldType.includes("Formula") ||
          (this.$route.meta.isEditPage && !this.field.isEditable),
        dropdownAutoWidth: false,
        multiple: this.field.isMultipleValue,
        separator: this.$systemSeparator,
      },
    };
  },
  components: {
    Select2,
  },
  computed: {
    isInvalid() {
      return (
        (this.field.isRequired || this.element.isRequired) &&
        String.isNullOrWhiteSpace(this.selectedId)
      );
    },
  },
  methods: {
    selection($event) {
      /* UI Change Event Fix */
      this.$root.formItemNewChangeEvent([this.field.publicId]);

      var el = $(String.format("#{0}", this.field.publicId)),
        elData = el.select2("data");

      this.selectedText = elData.map((u) => u.text).join(this.$systemSeparator);
      this.selectedId = elData.map((u) => u.id).join(this.$systemSeparator);

      this.$root.select2SetValue(
        el,
        this.field.isMultipleValue,
        this.selectedId,
        this.selectedText
      );

      if (!this.isCellEdit && !this.$route.meta.isDetailPage) {
        this.$root.select2PagedSelectlistSelection(this.field, this.selectedId);
        this.$root.calculateTriggerField(this.field);
      }
    },
  },
  mounted() {
    var select = $("#" + this.field.publicId);
    if (
      !String.isNullOrWhiteSpace(this.value) &&
      !String.isNullOrWhiteSpace(this.valueId)
    ) {
      if (!this.valueId.includes("error") && !this.value.includes("error")) {
        this.selectedText = this.value;
        this.selectedId = this.valueId;
        this.$root.select2SetValue(
          select,
          this.field,
          this.valueId,
          this.value,
          this.isCellEdit
        );
        this.oldValue = this.value;
        // this.selection();
      }
    }
    this.oldText = String.isNullOrWhiteSpace(this.value) ? "" : this.value;
    this.oldValue = String.isNullOrWhiteSpace(this.valueId) ? "" : this.valueId;
    select.attr("data-oldvalue", this.oldValue);

    if (!String.isNullOrWhiteSpace(this.field.controllingPublicId)) {
      this.$parent.childTrigger({
        field: this.field,
        isCellEdit: this.isCellEdit,
        value: this.valueId,
        rowData: this.rowData,
      });
    }
  },
};
</script>
