<template>
  <div class="btn-group" :class="groupClasses">
    <div class="dropdown">
      <button
        class="btn dropdown-toggle text-capitalize"
        :class="buttonClasses"
        type="button"
        :id="String.format('filter_dropdown_{0}', dropDownId)"
        :title="buttonName"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        :disabled="getDataRequestSent || isDisabled"
        @click="getData"
      >
        <span>
          <i :class="buttonIcon" v-if="buttonIcon"></i>
          {{ buttonName }}
        </span>
      </button>
      <ul
        class="dropdown-menu dropdown-menu-end fs-6 action-button-top-block-list-groups filter-dropdowns p-0"
        :aria-labelledby="String.format('filter_dropdown_{0}', dropDownId)"
      >
        <template v-if="items.length">
          <li
            class="dropdown-item p-2 active"
            v-if="
              !routeName.includes('export') && !routeName.includes('bulkAction')
            "
          >
            <input
              type="text"
              class="form-control"
              @keyup="searchDropdownItem"
              v-model="query"
              :placeholder="
                $t(
                  'BaseModelFields.Search',
                  {},
                  { locale: this.$store.state.activeLang }
                )
              "
            />
          </li>
          <li
            class="mb-1 mt-1 text-capitalize"
            v-for="item in items"
            :key="item.key"
          >
            <template v-if="routeName.includes('list')">
              <router-link
                type="button"
                class="dropdown-item"
                :to="{
                  name: 'list',
                  params: { key: this.$route.params.key },
                  query: {
                    filterId: item.key,
                  },
                }"
              >
                {{ item.value }}
              </router-link>
            </template>
            <template v-else-if="routeName.includes('calendar')">
              <router-link
                type="button"
                class="dropdown-item"
                :to="{
                  name: 'calendar',
                  params: {
                    key: this.$route.params.key,
                    calendarId: item.key,
                  },
                  query: {
                    filterId: this.$route.query.filterId,
                  },
                }"
              >
                {{ item.value }}
              </router-link>
            </template>
            <template v-else-if="routeName.includes('tree')">
              <router-link
                type="button"
                class="dropdown-item"
                :to="{
                  name: 'tree',
                  params: {
                    key: this.$route.params.key,
                    treeId: item.key,
                  },
                }"
              >
                {{ item.value }}
              </router-link>
            </template>
            <template v-else-if="routeName.includes('customPage')">
              <router-link
                type="button"
                class="dropdown-item"
                :to="{
                  name: 'customPage',
                  params: {
                    key: item.key,
                  },
                }"
              >
                {{ item.value }}
              </router-link>
            </template>
            <template v-else-if="routeName.includes('formTemplates')">
              <a
                type="button"
                class="dropdown-item cursor-pointer"
                @click="downloadTemplate($event, item)"
              >
                {{ item.value }}
              </a>
            </template>
            <template v-else-if="routeName.includes('export')">
              <a
                type="button"
                class="dropdown-item cursor-pointer fs-5"
                @click="downloadExportFile($event, item)"
              >
                <i class="fs-3" :class="item.iconClass"></i>
                {{ item.value }}
              </a>
            </template>
            <template v-else-if="routeName.includes('bulkAction')">
              <a
                type="button"
                class="dropdown-item cursor-pointer fs-5"
                @click="itemOnClick(routeName, item)"
                v-bind="item.attributes"
              >
                <i class="fs-4" :class="item.iconClass"></i>
                {{ item.value }}
              </a>
            </template>
          </li>
        </template>
        <template v-else-if="getDataRequestSent">
          <div class="alert alert-warning mb-0">
            {{
              $t(
                "BaseModelFields.PleaseWait",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </div>
        </template>
        <template v-else>
          <div class="alert alert-warning mb-0">
            {{
              $t(
                "BaseModelFields.NoRecordFound",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </div>
        </template>
      </ul>
    </div>
  </div>
</template>
<script>
import { showModal } from "@/core/helpers/dom";
import { createToast } from "mosha-vue-toastify";
import $ from "jquery";
export default {
  name: "FilterDropdown",
  emits: ["itemSelection"],
  props: [
    "routeName",
    "requestUrl",
    "buttonName",
    "buttonIcon",
    "buttonClasses",
    "groupClasses",
    "localData",
    "isProdApiRequest",
    "isBrsApiRequest",
    "downloadUrl",
    "resultType",
    "isDisabled",
  ],
  data() {
    return {
      setPageDto: this.$store.state.setPageDto,
      setPageFilterDto: this.$store.getters._getSetPageFilterDto,
      customPageDto: this.$store.state.customPageDto,
      dropDownId: String.newGuid(),
      items: [],
      query: "",
      getDataRequestSent: false,
      dataReceived: false,
      loadingBlock: String.format(
        '<div class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></div>',
        this.$t(
          "BaseModelFields.PleaseWaitShort",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
    };
  },
  watch: {
    "$store.state.setPageDto"() {
      this.setPageDto = this.$store.state.setPageDto;
    },
    "$store.getters._getSetPageFilterDto"() {
      this.setPageFilterDto = this.$store.getters._getSetPageFilterDto;
    },
  },
  methods: {
    itemOnClick(routeName, item) {
      this.$emit("itemSelection", { routeName: routeName, ...item });
    },
    addLoading($event) {
      var dropdown = $($event.target).closest(".dropdown"),
        button = dropdown.find("button"),
        list = dropdown.find("ul"),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block");

      button.prop("disabled", true).removeClass("dropdown-toggle");
      list.removeClass("show");

      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.loadingBlock);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      return dropdown;
    },
    downloadExportFile($event, selectedItem) {
      var gridId = "listDxable",
        setPageDto = this.setPageDto,
        root = this.$root,
        currentRecordPublicId = this.$route.params.id;

      if (selectedItem.parent) {
        root = selectedItem.parentGrid
          ? selectedItem.parentGrid.$root
          : selectedItem.parent.$root;
        gridId = selectedItem.parent.gridId;
        setPageDto = selectedItem.parent.masterDetailData
          ? selectedItem.parent.masterDetailData
          : selectedItem.parent.pageData;
      }

      var grid = this.$SXWindowFunctions.getDxDataGridInstance(gridId);
      if (!grid) return;

      var visibleColumns = grid
          .getVisibleColumns()
          .filter((f) => !String.isNullOrWhiteSpace(f.dataField))
          .map((m) => m.dataField),
        requestModel = {
          customObjectKey: setPageDto.customObject.key,
          size: setPageDto.pagedItems.itemCount,
          exportType: selectedItem.key,
          visibleFieldsFormulaNames: visibleColumns,
        };

      if (selectedItem.parent) {
        requestModel = {
          ...requestModel,
          lookupRelationPublicId: setPageDto.relationDetail.publicId,
          parentRecordPublicId: selectedItem.parentGrid
            ? selectedItem.parent.parentRecord[
                selectedItem.parent.parentSelf.primaryKey
              ]
            : currentRecordPublicId,
        };
      } else {
        requestModel = {
          ...requestModel,
          isForBulkUpdate: selectedItem.IsForBulkUpdate,
          viewFilterPublicId: String.isNullOrWhiteSpace(
            this.$route.query.filterId
          )
            ? setPageDto.filter.publicId
            : this.$route.query.filterId,
          isAdhoc: String.isNullOrWhiteSpace(this.$route.query.isAdhoc)
            ? false
            : Boolean.toBool(this.$route.query.isAdhoc),
        };
      }

      if (
        setPageDto.pagedItems.itemCount >= parseInt(this.$dataExportMaxPageSize)
      ) {
        root.dropdown = {
          type: this.routeName,
          name: this.buttonName,
          icon: this.buttonIcon,
          requestUrl: this.downloadUrl,
          model: requestModel,
          selected: selectedItem,
        };
        showModal(document.getElementById("dropdownApproveModal"));
        return;
      }

      var dropdown = this.addLoading($event);

      root.downloadViewFilterExportFile(
        this.downloadUrl,
        setPageDto.filter.name,
        requestModel,
        dropdown
      );
    },
    downloadTemplate($event, selectedItem) {
      var dropdown = this.addLoading($event);

      this.$root
        .getFormTemplateDetail(
          selectedItem.key,
          this.setPageDto.customObjectPublicId
        )
        .then((response) => {
          var formTemplateDetail = response.data;

          if (!formTemplateDetail) {
            createToast(
              this.$t(
                "BaseModelFields.FormTemplateNotFound",
                {},
                { locale: this.$store.state.activeLang }
              ),
              {
                showIcon: true,
                position: "top-right",
                type: "danger",
                transition: "zoom",
              }
            );
            this.$root.dropdownLoadingToggle(dropdown);
            return;
          }

          if (formTemplateDetail.reportTypeId == 1) {
            //word
            this.$root.downloadTemplate(
              String.format(
                this.downloadUrl,
                selectedItem.key,
                this.setPageDto.customObjectPublicId,
                this.$route.params.id
              ),
              selectedItem.value,
              dropdown
            );
            return;
          } else if (
            formTemplateDetail.reportTypeId == 2 ||
            formTemplateDetail.reportTypeId == 3
          ) {
            if (!formTemplateDetail.isPreviewEnabled) {
              createToast(
                this.$t(
                  "BaseModelFields.FormTemplateNotPreview",
                  {},
                  { locale: this.$store.state.activeLang }
                ),
                {
                  showIcon: true,
                  position: "top-right",
                  type: "warning",
                  transition: "zoom",
                }
              );
              this.$root.dropdownLoadingToggle(dropdown);
              return;
            }

            //dashboard report or xtra report
            var pageDto = this.setPageDto,
              allFields = pageDto.fields.find(
                (f) => f.isMainObject
              ).fieldModels,
              detail = this.$root.gridDataChange(
                [pageDto.responseValues],
                allFields,
                false
              ),
              isDashboardReport = formTemplateDetail.reportTypeId == 3,
              isXtraReport = formTemplateDetail.reportTypeId == 2,
              firstParameterFormulaName =
                formTemplateDetail.firstParameterFormulaName,
              secondParameterFormulaName =
                formTemplateDetail.secondParameterFormulaName,
              firstParameterIsNull = String.isNullOrWhiteSpace(
                firstParameterFormulaName
              ),
              secondParameterIsNull = String.isNullOrWhiteSpace(
                secondParameterFormulaName
              );

            detail = detail[0];

            if (firstParameterIsNull && secondParameterIsNull) {
              createToast(
                this.$t(
                  "BaseModelFields.FormTemplateParametersNullOrEmpty",
                  {},
                  { locale: this.$store.state.activeLang }
                ),
                {
                  showIcon: true,
                  position: "top-right",
                  type: "warning",
                  transition: "zoom",
                }
              );
              this.$root.dropdownLoadingToggle(dropdown);
              return;
            }

            var firstParamField = allFields.find(
              (f) => f.formulaName == firstParameterFormulaName
            );
            if (!firstParamField) {
              createToast(
                this.$t(
                  "BaseModelFields.FormTemplateFieldNotFound",
                  {},
                  { locale: this.$store.state.activeLang }
                ).replaceAll("FIELD_FORMULA_NAME", firstParameterFormulaName),
                {
                  showIcon: true,
                  position: "top-right",
                  type: "warning",
                  transition: "zoom",
                }
              );
              this.$root.dropdownLoadingToggle(dropdown);
              return;
            }

            var secondParamField = allFields.find(
              (f) => f.formulaName == secondParameterFormulaName
            );
            if (!secondParameterIsNull && !secondParamField) {
              createToast(
                this.$t(
                  "BaseModelFields.FormTemplateFieldNotFound",
                  {},
                  { locale: this.$store.state.activeLang }
                ).replaceAll("FIELD_FORMULA_NAME", secondParameterFormulaName),
                {
                  showIcon: true,
                  position: "top-right",
                  type: "warning",
                  transition: "zoom",
                }
              );
              this.$root.dropdownLoadingToggle(dropdown);
              return;
            }

            var firstParameterValue =
                detail[
                  this.$root.fieldTypes.select.includes(
                    firstParamField.fieldType
                  )
                    ? `${firstParameterFormulaName}${this.$fieldPublicIdFormulaExtension}`
                    : firstParameterFormulaName
                ],
              secondParameterValue = !secondParameterIsNull
                ? detail[
                    this.$root.fieldTypes.select.includes(
                      secondParamField.fieldType
                    )
                      ? `${secondParameterFormulaName}${this.$fieldPublicIdFormulaExtension}`
                      : secondParameterFormulaName
                  ]
                : "",
              parameterValues = [];

            parameterValues.push({
              formulaName: firstParameterFormulaName,
              value: String.isNullOrWhiteSpace(firstParameterValue)
                ? null
                : firstParameterValue,
            });

            if (!secondParameterIsNull) {
              parameterValues.push({
                formulaName: secondParameterFormulaName,
                value: String.isNullOrWhiteSpace(secondParameterValue)
                  ? null
                  : secondParameterValue,
              });
            }

            var valuesNotAvailable = parameterValues.filter(function (f) {
              return String.isNullOrWhiteSpace(f.value);
            });
            if (valuesNotAvailable.length > 0) {
              this.$root
                .getRecordByFields(
                  this.$route.params.key,
                  detail["PUBLICID"],
                  valuesNotAvailable.map((m) => m.formulaName)
                )
                .then((response) => {
                  if (response) {
                    parameterValues
                      .filter(function (f) {
                        return String.isNullOrWhiteSpace(f.value);
                      })
                      .forEach((parameterValue) => {
                        var valueObj = response[parameterValue.formulaName],
                          value = !String.isNullOrWhiteSpace(
                            valueObj.publicIdValue
                          )
                            ? valueObj.publicIdValue
                            : valueObj.value;
                        if (String.isNullOrWhiteSpace(value)) {
                          parameterValue.value = "";
                        } else {
                          parameterValue.value = value;
                        }
                      });

                    var reportingModel = {
                      url: isXtraReport
                        ? `/XtraReporting/Viewer/${formTemplateDetail.xtraReportPublicId}`
                        : `/DashboardReporting/Viewer/${formTemplateDetail.dashboardPublicId}`,
                      setXrmParameterOne:
                        parameterValues.length > 0
                          ? parameterValues[0].value
                          : "",
                      setXrmParameterTwo:
                        parameterValues.length > 1
                          ? parameterValues[1].value
                          : "",
                    };

                    this.$root
                      .createKeyForCurrentUser(reportingModel)
                      .then((response) => {
                        var result = response.data,
                          token = result.data;
                        if (!String.isNullOrWhiteSpace(token)) {
                          var url = `${this.$root.getReportingWebSiteUrl()}/${
                            this.$setXRMReportAuthorizeActionUrl
                          }?key=${encodeURIComponent(token)}`;

                          window.open(url, "_blank");
                        }
                      })
                      .catch((err) => {
                        //TODO: Error
                      });
                  }
                })
                .catch((err) => {
                  //TODO: Error
                });
            } else {
              var reportingModel = {
                url: isXtraReport
                  ? `/XtraReporting/Viewer/${formTemplateDetail.xtraReportPublicId}`
                  : `/DashboardReporting/Viewer/${formTemplateDetail.dashboardPublicId}`,
                setXrmParameterOne:
                  parameterValues.length > 0 ? parameterValues[0].value : "",
                setXrmParameterTwo:
                  parameterValues.length > 1 ? parameterValues[1].value : "",
              };

              this.$root
                .createKeyForCurrentUser(reportingModel)
                .then((response) => {
                  var result = response.data,
                    token = result.data;
                  if (!String.isNullOrWhiteSpace(token)) {
                    var url = `${this.$root.getReportingWebSiteUrl()}/${
                      this.$setXRMReportAuthorizeActionUrl
                    }?key=${encodeURIComponent(token)}`;

                    window.open(url, "_blank");
                  }
                })
                .catch((err) => {
                  //TODO: Error
                });
            }

            this.$root.dropdownLoadingToggle(dropdown);
          }
        })
        .catch((err) => {
          //TODO: Error
        });
    },
    searchDropdownItem() {
      var value = this.query.toLowerCase();
      $(String.format(".filter-dropdowns li:not(:first)")).filter(function () {
        $(this).toggle($(this).text().trim().toLowerCase().indexOf(value) > -1);
      });
    },
    getData() {
      if (this.localData) {
        this.items = this.localData;
        this.dataReceived = true;
        return;
      }

      if (String.isNullOrWhiteSpace(this.requestUrl) || this.dataReceived)
        return;

      var instance = this.$appAxios;
      if (this.isProdApiRequest) {
        instance = this.$prodAppAxios;
      } else if (this.isBrsApiRequest) {
        instance = this.$brsAxios;
      }

      this.getDataRequestSent = true;
      this.items = [];
      instance
        .get(this.requestUrl)
        .then((response) => {
          var result = response.data;
          if (result.items) {
            if (
              !String.isNullOrWhiteSpace(this.resultType) &&
              this.resultType === "keyValue"
            ) {
              this.items = result.items;
            } else {
              result.items.forEach((element) => {
                this.items.push({ key: element.publicId, value: element.name });
              });
            }
          } else if (result.keyValues) {
            result.keyValues.forEach((element) => {
              this.items.push({ key: element.key, value: element.value });
            });
          } else {
            result.forEach((element) => {
              this.items.push({ key: element.publicId, value: element.name });
            });
          }

          this.items = this.items.filter(
            (f) =>
              f.key !== this.$route.params.id &&
              f.key !== this.$route.params.filterId &&
              f.key !== this.$route.params.calendarId &&
              f.key !== this.$route.params.treeId
          );

          if (!String.isNullOrWhiteSpace(this.setPageFilterDto)) {
            this.items = this.items.filter(
              (f) => f.key !== this.setPageFilterDto.publicId
            );
          }

          if (!String.isNullOrWhiteSpace(this.customPageDto)) {
            this.items = this.items.filter(
              (f) => f.key !== this.customPageDto.formulaName
            );
          }

          if (this.$store.state.isMultiLanguage) {
            this.items.forEach((item) => {
              var localizationValue =
                this.$store.getters.getLocalizationValuesByParameters({
                  parentId: null,
                  itemPublicId: item.key,
                });
              if (localizationValue) {
                item.value = localizationValue.value;
              }
            });
          }
        })
        .finally(() => {
          this.getDataRequestSent = false;
          this.dataReceived = true;
        })
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });
    },
  },
};
</script>
