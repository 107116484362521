<template>
  <input
    type="text"
    class="dx-texteditor-input shadow-none text-end"
    :placeholder="placeholder"
    :disabled="disabled"
    :autocomplete="$isAutoComplete"
    :spellcheck="$isTextSpellCheck"
    :name="template.id"
    :id="template.id"
    :maxlength="maxLength"
    :data-oldvalue="oldValue"
    v-model="numericTextBoxValue"
    @change="numericTextBoxOnChange"
  />
</template>
<script>
import $ from "jquery";
/* https://github.com/RobinHerbots/Inputmask */
import Inputmask from "inputmask";
export default {
  name: "NumericTextBox",
  props: ["template", "value", "disabled", "rowData"],
  data() {
    return {
      numericTextBoxValue: this.value,
      placeholder: this.template.field.fieldType.includes("Formula")
        ? this.$t(
            "BaseModelFields.FieldIsCalcForSystem",
            {},
            { locale: this.$store.state.activeLang }
          )
        : this.template.parent.$root.inputMaskPatternReplace(
            this.template.field
          ),
      maxLength: String.isNullOrWhiteSpace(
        this.template.field.inputMaskPattern.length
      )
        ? this.template.field.length
        : this.template.field.inputMaskPattern.length,
      formatInfo: this.template.parent.$root.getNumberFormat(),
      oldValue: "",
      inputMasked: false,
    };
  },
  mounted() {
    this.applyInputMask();
  },
  computed: {
    currentSelf() {
      return this.template.child ? this.template.child : this.template.parent;
    },
  },
  methods: {
    onInput() {
      if (!this.inputMasked) {
        this.applyInputMask();
      }
    },
    applyInputMask() {
      var selector = document.getElementById(this.template.id);
      if (!String.isNullOrWhiteSpace(selector)) {
        var im = new Inputmask({
          alias: "decimal",
          groupSeparator: this.formatInfo.group,
          radixPoint: this.formatInfo.decimal,
          autoGroup: this.template.field.isThousandSeparator,
          clearMaskOnLostFocus: true,
          allowMinus: true,
          radixFocus: true,
          positionCaretOnClick: "none",
          autoUnmask: false,
          integerDigits: this.template.field.length,
          digits: this.template.field.decimalPlaces,
        });
        im.mask(selector);

        this.inputMasked = true;
      }
    },
    numericTextBoxOnChange(e) {
      this.numericTextBoxValue = $("#" + this.template.id).val();

      this.currentSelf.editCellTemplateValueChangedForNumberFieldTypes(
        this.template,
        this.numericTextBoxValue
      );

      this.template.parent.$root.calculateTriggerFieldForGrid(
        this.currentSelf,
        this.template
      );
    },
  },
};
</script>
