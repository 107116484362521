<template>
  <div
    class="accordion mb-2 mt-2"
    :id="String.format('pageFilter_{0}', gridId)"
    ref="accordion"
  >
    <div class="accordion-item">
      <h2
        class="accordion-header"
        :id="String.format('pageFilter_{0}_head', gridId)"
      >
        <button
          class="accordion-button table-grid-title"
          :class="{
            collapsed:
              pageData.filter.pageCriteria.isPageFilterCollapsed ||
              isAdhocFilter,
          }"
          type="button"
          data-bs-toggle="collapse"
          :data-bs-target="String.format('#pageFilter_{0}_tab1', gridId)"
          aria-expanded="true"
          :aria-controls="String.format('pageFilter_{0}_tab1', gridId)"
        >
          <p class="text-capitalize fs-5 mb-0">
            {{
              $t(
                "Components.PageFilter.Title",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </p>
        </button>
      </h2>
      <div
        :id="String.format('pageFilter_{0}_tab1', gridId)"
        class="accordion-collapse collapse"
        :class="{
          show:
            !pageData.filter.pageCriteria.isPageFilterCollapsed &&
            !isAdhocFilter,
        }"
        :aria-labelledby="String.format('pageFilter_{0}_head', gridId)"
        :data-bs-parent="String.format('pageFilter_{0}', gridId)"
      >
        <div class="accordion-body">
          <AlertBox type="warning" :messages="responseError" />
          <table
            class="table table-hover shadow-none"
            :id="String.format('pageFilterTable_{0}', gridId)"
          >
            <tbody>
              <tr
                v-for="(row, i) in criteriaFields"
                role="criteria"
                :key="i"
                :data-number="row.criteria.number"
                :data-rowNumber="i"
                :data-operatorPublicId="row.criteria.operatorPublicId"
                :data-operatorName="row.criteria.operatorName"
                :data-fieldPublicId="row.criteria.fieldPublicId"
                :data-fieldType="row.field.fieldType"
                :data-fieldFormulaName="row.field.formulaName"
                :data-isMultiple="row.field.isMultipleValue"
                :data-controllingpublicid="row.field.controllingPublicId"
                :data-organizationalUnitDepth="
                  row.field.organizationalUnitDepth
                "
                :data-organizationalUnitFilterType="
                  row.field.organizationalUnitFilterType
                "
                :data-organizationalUnitGroupPublicIds="
                  row.field.organizationalUnitGroupPublicIds
                "
                :data-organizationalUnitIncludeItself="
                  row.field.organizationalUnitIncludeItself
                "
                :data-organizationalunitname="row.field.organizationalUnitName"
                :data-predefinedPublicId="row.field.predefinedPublicId"
                :data-viewFilterPublicId="row.field.viewFilterPublicId"
                :data-publicid="row.field.publicId"
                data-adHocCriteria=""
              >
                <td class="align-middle p-2 col-md-3">
                  <label
                    :for="`pageFilterInput1_${row.criteria.fieldPublicId}_${i}`"
                    class="form-label fs-5 mb-0"
                    >{{ row.criteria.fieldName }}</label
                  >
                </td>
                <td class="align-middle p-2 col-md-9">
                  <PageFilterFormItem
                    ref="formItem"
                    :index="i"
                    :criteria="row.criteria"
                    :field="row.field"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div class="col-md-12 text-end">
            <button
              type="button"
              class="btn btn-success me-2 btn-page-filter-search"
              @click="search"
            >
              <span>
                <i class="fa fa-search"></i>
                {{
                  $t(
                    "BaseModelFields.Search",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </span>
            </button>
            <button type="button" class="btn btn-danger" @click="clear">
              <i class="fa fa-sync"></i>
              {{
                $t(
                  "BaseModelFields.Clear",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import PageFilterFormItem from "@/components/custom/set-pages/PageFilterFormItem.vue";
import { createToast } from "mosha-vue-toastify";
export default {
  name: "PageFilter",
  props: ["gridId", "pageData", "fields"],
  data() {
    return {
      isAdhocFilter: String.isNullOrWhiteSpace(this.$route.query.isAdhoc)
        ? false
        : this.$route.query.isAdhoc,
      actionName: "/Lcdp-ViewFilterAdhoc",
      responseError: [],
      criteriaFields: [], //only grid data fields and modify criterias
      searchButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "BaseModelFields.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
    };
  },
  mounted() {
    var self = this,
      adhocCriterias = this.pageData.adHocCriteria.criterias;
    this.pageData.filter.pageCriteria.criterias.forEach((criteria, i) => {
      var gridField = self.fields.find(
        (f) => f.publicId == criteria.fieldPublicId
      );
      if (gridField) {
        if (this.$store.state.isMultiLanguage) {
          var fieldLocalizationValue =
            this.$store.getters.getLocalizationValuesByParameters({
              parentId: gridField.customObjectPublicId,
              itemFormulaName: gridField.formulaName,
              itemTypeId: 2,
            });
          if (fieldLocalizationValue) {
            gridField.name = criteria.fieldName = fieldLocalizationValue.value;
          }
        }

        if (adhocCriterias.length == 0) {
          self.criteriaFields.push({
            criteria: criteria,
            field: gridField,
          });
        } else {
          var detailCriteria = null;
          if (criteria.operatorName == "between") {
            var filters = adhocCriterias.filter(function (f) {
              return f.fieldPublicId == criteria.fieldPublicId;
            });
            var obj = {
              fieldName: criteria.fieldName,
              fieldPublicId: criteria.fieldPublicId,
              number: criteria.number,
              operatorName: criteria.operatorName,
              operatorPublicId: criteria.operatorPublicId,
              value: null,
              text: null,
              value2: null,
              text2: null,
            };
            filters.forEach((betweenCriteria) => {
              //"greather_than_or_equal_to"
              if (betweenCriteria.operatorName == "greather_than_or_equal_to") {
                obj.value = betweenCriteria.value;
                obj.text = betweenCriteria.text;
              }
              //less_than_or_equal_to
              else if (
                betweenCriteria.operatorName == "less_than_or_equal_to"
              ) {
                obj.value2 = betweenCriteria.value;
                obj.text2 = betweenCriteria.text;
              }
            });
            self.criteriaFields.push({
              criteria: obj,
              field: gridField,
            });
          } else {
            detailCriteria = adhocCriterias.find(
              (f) =>
                f.fieldPublicId == criteria.fieldPublicId &&
                f.operatorPublicId == criteria.operatorPublicId
            );
            if (detailCriteria) {
              detailCriteria.fieldName = gridField.name;
              self.criteriaFields.push({
                criteria: detailCriteria,
                field: gridField,
              });
            } else {
              self.criteriaFields.push({
                criteria: criteria,
                field: gridField,
              });
            }
          }
        }
      }
    });
  },
  components: {
    PageFilterFormItem,
  },
  methods: {
    getOperatorPublicId(fieldType, operatorType) {
      if (fieldType === "Number" && operatorType === "greater_than_or_equal")
        return "AEDE83DA57B24467B5465D582FA1BEB6";
      if (fieldType === "Number" && operatorType === "less_than_or_equal")
        return "D69B63FEB8654E2BAE3C4ED9CA90AF7A";
      if (
        fieldType === "FormulaNumber" &&
        operatorType === "greater_than_or_equal"
      )
        return "010973EB54414C6E940AF24CBF03BCFA";
      if (
        fieldType === "FormulaNumber" &&
        operatorType === "less_than_or_equal"
      )
        return "7D9C72EA57844996A89BA6DB82F29937";
      if (fieldType === "Date" && operatorType === "greater_than_or_equal")
        return "20EB4BA7E4464B2483E7428E0517B5FD";
      if (fieldType === "Date" && operatorType === "less_than_or_equal")
        return "BD7CE1D5EDFD419BA7B1578EBE5BC680";
      if (fieldType === "DateTime" && operatorType === "greater_than_or_equal")
        return "EEC2045EA6E243B3A01E05C6AFD0FB71";
      if (fieldType === "DateTime" && operatorType === "less_than_or_equal")
        return "4F9D309851DA4C5A909CDBEAC74803A5";
      if (
        fieldType === "FormulaDateTime" &&
        operatorType === "greater_than_or_equal"
      )
        return "EBC25606797A4BDABB1D2AEBC8F9FD5B";
      if (
        fieldType === "FormulaDateTime" &&
        operatorType === "less_than_or_equal"
      )
        return "381E280E43C54ED5BE88821F638F3511";
      if (
        fieldType === "FormulaDate" &&
        operatorType === "greater_than_or_equal"
      )
        return "75F04154E37C4B948BFAB949F5688CAF";
      if (fieldType === "FormulaDate" && operatorType === "less_than_or_equal")
        return "547572437C35442483CBFBA04187C1EE";
      if (
        fieldType === "RollupDateTime" &&
        operatorType === "greater_than_or_equal"
      )
        return "EBC25606797A4BDBBB1D2AEBC8F9FD5B";
      if (
        fieldType === "RollupDateTime" &&
        operatorType === "less_than_or_equal"
      )
        return "381E280E43C54ED6BE88821F638F3511";
      if (
        fieldType === "RollupDate" &&
        operatorType === "greater_than_or_equal"
      )
        return "75F04154E37C4B958BFAB949F5688CAF";
      if (fieldType === "RollupDate" && operatorType === "less_than_or_equal")
        return "547572437C35441883CBFBA04187C1EE";

      return null;
    },
    getPageFilterData() {
      var pageFilter = $(String.format("#pageFilter_{0}", this.gridId)),
        table = pageFilter.find(".table"),
        rows = table.find("tr[role='criteria']"),
        filterList = [],
        number = 0,
        condition = [],
        self = this,
        model = {
          customObjectPublicId: this.pageData.customObject.publicId,
          viewFilterPublicId: this.pageData.filter.publicId,
          criteriaExpression: "",
          criterias: [],
        };

      $.each(rows, function (i, r) {
        var row = $(r),
          rowInfo = row,
          input1Selector = `#pageFilterInput1_${rowInfo.data(
            "fieldpublicid"
          )}_${i}`,
          input2Selector = `#pageFilterInput2_${rowInfo.data(
            "fieldpublicid"
          )}_${i}`,
          input1 = row.find(input1Selector),
          input2 = row.find(input2Selector),
          filterValue = input1.val(),
          filterText = filterValue;

        if (rowInfo.data("fieldtype") === "Phone") {
          filterValue = $(
            `[name="pageFilterInput1_${rowInfo.data(
              "fieldpublicid"
            )}_${i}_full_phone_number"]`
          ).val();
          filterText = filterValue;
        }

        if (!String.isNullOrWhiteSpace(filterValue) && number !== 0) {
          condition.push(" AND ");
        }

        if (rowInfo.data("operatorname") !== "between") {
          if (!String.isNullOrWhiteSpace(filterValue)) {
            number++;
            if (
              $(input1Selector).hasClass("select2-hidden-accessible") &&
              $(input1Selector).select2("data")
            ) {
              if (
                !String.isNullOrWhiteSpace(
                  $(input1Selector).select2("data").text
                )
              ) {
                filterText = $(input1Selector).select2("data").text;
              } else {
                var mappedData = $.map(
                  $(input1Selector).select2("data"),
                  function (data) {
                    return data.text;
                  }
                );
                filterText = mappedData;
              }
            }
            filterList.push({
              number: number,
              fieldPublicId: rowInfo.data("fieldpublicid"),
              operatorPublicId: rowInfo.data("operatorpublicid"),
              value: Array.isArray(filterValue)
                ? filterValue.join(self.$systemSeparator)
                : filterValue,
              text: Array.isArray(filterText)
                ? filterText.join(self.$systemSeparator)
                : filterText,
            });

            condition.push("{");
            condition.push(number);
            condition.push("}");
          }
        } else {
          if (!String.isNullOrWhiteSpace(filterValue)) {
            number++;
            filterList.push({
              number: number,
              fieldPublicId: rowInfo.data("fieldpublicid"),
              operatorPublicId: self.getOperatorPublicId(
                rowInfo.data("fieldtype"),
                "greater_than_or_equal"
              ),
              value: filterValue,
              text: filterText,
            });

            condition.push("{");
            condition.push(number);
            condition.push("}");
          }

          if (!String.isNullOrWhiteSpace(input2.val())) {
            if (number !== 0) {
              condition.push(" AND ");
            }

            number++;
            filterList.push({
              number: number,
              fieldPublicId: rowInfo.data("fieldpublicid"),
              operatorPublicId: self.getOperatorPublicId(
                rowInfo.data("fieldtype"),
                "less_than_or_equal"
              ),
              value: input2.val(),
              text: input2.val(),
            });

            condition.push("{");
            condition.push(number);
            condition.push("}");
          }
        }
      });

      model.criteriaExpression = condition.join("");
      model.criterias = filterList;

      return model;
    },
    search() {
      var model = this.getPageFilterData();
      if (model.criterias.length == 0) {
        createToast(
          this.$t(
            "Components.PageFilter.ModelMinLengthWarningMessage",
            {},
            { locale: this.$store.state.activeLang }
          ),
          {
            showIcon: true,
            position: "top-right",
            type: "warning",
            transition: "zoom",
          }
        );
        return;
      }

      var button = $(String.format(".btn-page-filter-search")),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block");

      button.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.searchButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.responseError = [];
      this.$appAxios
        .post(this.actionName, model)
        .then((response) => {
          firstSpan.show();
          loadingBlock.hide();
          button.prop("disabled", false);

          var result = response.data;
          if (result.isOk && !String.isNullOrWhiteSpace(result.publicId)) {
            if (this.$route.meta.isListPage) {
              this.$router.push({
                name: "list",
                query: {
                  filterId: result.publicId,
                  isAdhoc: true,
                },
              });
            } else if (this.$route.meta.isCalendarPage) {
              this.$router.push({
                name: "calendar",
                query: {
                  filterId: result.publicId,
                  isAdhoc: true,
                },
              });
            }
          } else {
            this.responseError.push(result.msg);
          }
        })
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });
    },
    clear() {
      this.criteriaFields.forEach((row) => {
        row.criteria.text = "";
        row.criteria.value = null;
      });

      var self = this,
        dateFields = this.$refs.formItem.filter(function (f) {
          return (
            self.$root.fieldTypes.date.includes(f.field.fieldType) ||
            self.$root.fieldTypes.datetime.includes(f.field.fieldType)
          );
        });
      if (dateFields.length > 0) {
        dateFields.forEach((component) => {
          if (component.$refs.startDate) {
            component.$refs.startDate.$refs.datePicker.instance.reset();
          }

          //between date component
          if (component.$refs.endDate) {
            component.$refs.endDate.$refs.datePicker.instance.reset();
          }
        });
      }

      var selectFilterItems = $(
        `#pageFilterTable_${this.$listPageGridId} tr[role="criteria"] td select`
      );
      if (selectFilterItems) {
        selectFilterItems.each(function (i, v) {
          var tr = $(v).closest("tr");
          self.$root.select2SetValue($(v), tr.data("ismultiple"), null, null);
        });
      }
    },
  },
};
</script>
