<template>
  <div
    :id="modalId"
    class="modal fade"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    data-sx-system-modal="true"
    aria-hidden="true"
  >
    <div class="modal-dialog sx-modal-responsive modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-capitalize" v-if="dropdown">
            <i :class="dropdown.icon" v-if="dropdown.icon"></i>
            {{ dropdown.name }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <template v-if="dropdown && dropdown.type == 'export'">
            <form class="form w-100" id="dataExportEmailForm">
              <div class="fv-row">
                <label class="form-label fs-6 fw-bolder required" for="email">{{
                  $t(
                    "BaseModelFields.Email",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <div class="position-relative">
                  <input
                    class="form-control form-control-lg"
                    type="email"
                    name="email"
                    id="email"
                    :autocomplete="$isAutoComplete"
                    :spellcheck="$isTextSpellCheck"
                    required
                    v-on:keyup.enter="approve"
                    v-model="model.emailAddress"
                  />
                  <div class="invalid-tooltip">
                    <template
                      v-if="String.isNullOrWhiteSpace(model.emailAddress)"
                    >
                      {{
                        this.$t(
                          "FieldWarnings.TextNotValid",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </template>
                    <template v-else>
                      {{
                        this.$t(
                          "FieldWarnings.EmailNotValid",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </template>
                  </div>
                </div>
              </div>
            </form>
            <div class="alert alert-info mt-3 mb-0">
              {{
                $t(
                  "Components.DropDown.ExportMaxPageSizeWarning",
                  {},
                  { locale: this.$store.state.activeLang }
                )
                  .replaceAll(
                    "MAX_PAGE_SIZE",
                    String.numberWithCommas(
                      $dataExportMaxPageSize,
                      this.$store.state.activeLang
                    )
                  )
                  .replaceAll("SELECTED_TYPE", dropdown.selected.value)
              }}
            </div>
          </template>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success btn-sm" @click="approve">
            <i class="fas fa-check"></i>
            {{
              $t(
                "BaseModelFields.Yes",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
          <button
            type="button"
            class="btn btn-danger btn-sm"
            data-bs-dismiss="modal"
          >
            <i class="fas fa-times"></i>
            {{
              $t(
                "BaseModelFields.Cancel",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { hideModal } from "@/core/helpers/dom";
import { createToast } from "mosha-vue-toastify";
export default {
  name: "DropdownApprove",
  props: ["dropdown"],
  data() {
    return {
      model: {
        emailAddress: this.$store.getters.email,
      },
      modalId: "dropdownApproveModal",
    };
  },
  components: {},
  methods: {
    approve() {
      if (this.dropdown.type == "export") {
        var form = $("form#dataExportEmailForm");
        form.addClass("was-validated");
        if (!form[0].checkValidity()) {
          return;
        }

        var dropdownModel = this.dropdown.model;
        dropdownModel.sendEmail = this.model.emailAddress;

        hideModal(document.getElementById(this.modalId));
        this.$appAxios.post(this.dropdown.requestUrl, dropdownModel);
        createToast(
          this.$t(
            "BaseModelFields.SuccessMessage",
            {},
            { locale: this.$store.state.activeLang }
          ),
          {
            showIcon: true,
            position: "top-right",
            type: "success",
            transition: "zoom",
          }
        );
      }
    },
  },
};
</script>
