<template>
  <template
    v-if="
      field.fieldType == 'Date' ||
      field.fieldType == 'DateTime' ||
      field.fieldType == 'FormulaDateTime' ||
      field.fieldType == 'FormulaDate' ||
      field.fieldType == 'RollupDate' ||
      field.fieldType == 'RollupDateTime'
    "
  >
    <template
      v-if="
        field.fieldType == 'Date' ||
        field.fieldType == 'FormulaDate' ||
        field.fieldType == 'RollupDate'
      "
    >
      <template v-if="criteria.operatorName == 'between'">
        <div class="row">
          <div class="col-md-6">
            <DatePicker
              ref="startDate"
              type="date"
              :value="criteria.value"
              @valueChanged="betweenDateStartChanged"
              :id="inputId"
            />
          </div>
          <div class="col-md-6">
            <DatePicker
              ref="endDate"
              type="date"
              :value="criteria.value2"
              :minDate="minBetweenDate || criteria.value"
              :id="inputIdForBetweenInput"
            />
          </div>
        </div>
      </template>
      <template v-else>
        <DatePicker
          ref="startDate"
          type="date"
          :value="criteria.value"
          :id="inputId"
        />
      </template>
    </template>
    <template
      v-else-if="
        field.fieldType == 'DateTime' ||
        field.fieldType == 'FormulaDateTime' ||
        field.fieldType == 'RollupDateTime'
      "
    >
      <template v-if="criteria.operatorName == 'between'">
        <div class="row">
          <div class="col-md-6">
            <DatePicker
              ref="startDate"
              type="datetime"
              :value="criteria.value"
              @valueChanged="betweenDateStartChanged"
              :id="inputId"
            />
          </div>
          <div class="col-md-6">
            <DatePicker
              ref="endDate"
              type="datetime"
              :value="criteria.value2"
              :minDate="minBetweenDate || criteria.value"
              :id="inputIdForBetweenInput"
            />
          </div>
        </div>
      </template>
      <template v-else>
        <DatePicker
          ref="startDate"
          type="datetime"
          :value="criteria.value"
          :id="inputId"
        />
      </template>
    </template>
  </template>
  <template v-else-if="$root.fieldTypes.number.includes(field.fieldType)">
    <template v-if="criteria.operatorName == 'between'">
      <div class="row">
        <div class="col-md-6">
          <input
            type="text"
            class="form-control"
            :value="criteria.value"
            :maxlength="numberField.maxLength"
            :placeholder="numberField.placeholder"
            :id="inputId"
            :name="inputId"
          />
        </div>
        <div class="col-md-6">
          <input
            type="text"
            class="form-control"
            :value="criteria.value2"
            :maxlength="numberField.maxLength"
            :placeholder="numberField.placeholder"
            :id="inputIdForBetweenInput"
            :name="inputIdForBetweenInput"
          />
        </div>
      </div>
    </template>
    <template v-else>
      <input
        type="text"
        class="form-control"
        :value="criteria.value"
        :maxlength="numberField.maxLength"
        :placeholder="numberField.placeholder"
        :id="inputId"
        :name="inputId"
      />
    </template>
  </template>
  <template v-else-if="this.$root.fieldTypes.phone.includes(field.fieldType)">
    <input
      type="tel"
      class="form-control sx-phone-control"
      :value="criteria.value"
      :id="inputId"
    />
  </template>
  <template
    v-else-if="
      criteria.operatorName == 'equals' ||
      criteria.operatorName == 'not_equal_to' ||
      criteria.operatorName == 'not_includes' ||
      criteria.operatorName == 'includes'
    "
  >
    <template
      v-if="
        this.$root.fieldTypes.select.includes(field.fieldType) ||
        field.fieldType == 'Checkbox'
      "
    >
      <Select2
        :placeholder="
          $t(
            'BaseModelFields.Choose',
            {},
            { locale: this.$store.state.activeLang }
          )
        "
        @select="select2Selection($event)"
        :id="inputId"
        :settings="
          field.fieldType == 'Checkbox' || field.fieldType == 'SelectList'
            ? selectlistSettings
            : pagedSelectlistSettings
        "
      />
    </template>
    <template v-else>
      <input
        type="text"
        class="form-control"
        :value="criteria.value"
        :id="inputId"
      />
    </template>
  </template>
  <template v-else>
    <input
      type="text"
      class="form-control"
      :value="criteria.value"
      :id="inputId"
    />
  </template>
</template>
<script>
import $ from "jquery";
import Select2 from "vue3-select2-component";
import DatePicker from "@/components/devexpress/dateboxes/DatePickerByType.vue";

export default {
  name: "PageFilterFormItems",
  props: ["index", "field", "criteria"],
  data() {
    return {
      minBetweenDate: null,
      inputId: `pageFilterInput1_${this.field.publicId}_${this.index}`,
      inputIdForBetweenInput: `pageFilterInput2_${this.field.publicId}_${this.index}`,
      numberField: {
        format: this.$root.getNumberFormat(),
        maxLength: String.isNullOrWhiteSpace(this.field.inputMaskPattern.length)
          ? this.field.length
          : this.field.inputMaskPattern.length,
        placeholder: this.$root.inputMaskPatternReplace(this.field),
      },
      selectlistSettings: {
        dropdownParent: null,
        language: this.$root.getSelect2Locale(this.$store.state.activeLang),
        theme: "bootstrap-5",
        allowClear: true,
        closeOnSelect: true,
        maximumSelectionSize: 99,
        minimumInputLength: 0,
        dropdownAutoWidth: false,
        disabled: !this.field.isActive,
        multiple:
          this.field.isMultipleValue ||
          this.criteria.operatorName == "includes" ||
          this.criteria.operatorName == "not_includes",
        separator: this.$systemSeparator,
        ajax: this.$root.getSelect2AjaxConfiguration(
          this.field,
          String.format("rws-FieldItems?id={0}", this.field.publicId)
        ),
        templateResult: function (d) {
          return Object.isHTML(d.text) ? $(d.text) : d.text;
        },
        templateSelection: function (s) {
          return Object.isHTML(s.text) ? $(s.text) : s.text;
        },
      },
      pagedSelectlistSettings: {
        dropdownParent: null,
        language: this.$root.getSelect2Locale(this.$store.state.activeLang),
        theme: "bootstrap-5",
        allowClear: true,
        closeOnSelect: true,
        maximumSelectionSize: 99,
        minimumInputLength: 0,
        disabled: !this.field.isActive,
        multiple:
          this.field.isMultipleValue ||
          this.criteria.operatorName == "includes" ||
          this.criteria.operatorName == "not_includes",
        separator: this.$systemSeparator,
        dropdownAutoWidth: false,
        ajax: this.$root.getSelect2AjaxConfiguration(
          this.field,
          this.setRequestUrl()
        ),
        templateResult: function (d) {
          return Object.isHTML(d.text) ? $(d.text) : d.text;
        },
        templateSelection: function (s) {
          return Object.isHTML(s.text) ? $(s.text) : s.text;
        },
      },
    };
  },
  mounted() {
    if (this.$root.fieldTypes.phone.includes(this.field.fieldType)) {
      this.$root.preparePhoneNumber(this.inputId);
    }

    if (this.$root.fieldTypes.number.includes(this.field.fieldType)) {
      this.$SXWindowFunctions.prepareNumberInputWithLimitedIntegerDigits(
        document.getElementById(this.inputId),
        this.numberField.format.group,
        this.numberField.format.decimal,
        this.field.isThousandSeparator,
        this.field.decimalPlaces,
        this.field.length,
        this.criteria.value
      );
      if (this.criteria.operatorName == "between") {
        this.$SXWindowFunctions.prepareNumberInputWithLimitedIntegerDigits(
          document.getElementById(this.inputIdForBetweenInput),
          this.numberField.format.group,
          this.numberField.format.decimal,
          this.field.isThousandSeparator,
          this.field.decimalPlaces,
          this.field.length,
          this.criteria.value2
        );
      }
    }

    if (
      !String.isNullOrWhiteSpace(this.criteria.value) &&
      !String.isNullOrWhiteSpace(this.criteria.text) &&
      (this.$root.fieldTypes.select.includes(this.field.fieldType) ||
        this.field.fieldType == "Checkbox")
    ) {
      this.$root.select2SetValue(
        $("#" + this.inputId),
        this.criteria.value.includes(this.$systemSeparator),
        this.criteria.value,
        this.criteria.text
      );
    }
  },
  components: {
    Select2,
    DatePicker,
  },
  methods: {
    select2Selection($event) {
      /* UI Change Event Fix */
      this.$root.formItemNewChangeEvent([this.inputId]);
    },
    betweenDateStartChanged(value) {
      this.minBetweenDate = null;
      if (!String.isNullOrWhiteSpace(value)) {
        this.minBetweenDate = value;
      }
    },
    setRequestUrl() {
      var requestUrl = null;
      switch (this.field.fieldType) {
        case "Predefined":
          requestUrl = this.$root.getFieldRequestUrl("predefined", {
            parentId: this.field.predefinedPublicId,
          });
          break;
        case "OrganizationalUnit":
        case "FormulaOrganizationalUnit":
          requestUrl = this.$root.getFieldRequestUrl("organizationalunit", {
            publicId: this.field.publicId,
            name: this.field.organizationalUnitName,
            filterType: this.field.organizationalUnitFilterType,
            groupIds: this.field.organizationalUnitGroupPublicIds,
            includeItself: this.field.organizationalUnitIncludeItself,
            depth: this.field.organizationalUnitDepth,
            isAddCurrentKeys: false,
          });
          break;
        case "SelectList":
        case "Checkbox":
          requestUrl = this.$root.getFieldRequestUrl("select", {
            id: this.field.publicId,
          });
          break;
        case "Lookup":
          requestUrl = this.$root.getFieldRequestUrl("lookup", {
            coId: this.field.customObjectPublicId,
            fieldPublicId: this.field.publicId,
          });
          break;
        default:
          break;
      }

      return requestUrl;
    },
  },
};
</script>
