import { createRouter, createWebHashHistory } from "vue-router";
import store from "@/store";

const homeAddressUrl = process.env.VUE_APP_HOME_ADDRESS_URL,
  routes = [
    {
      name: "Dashboard",
      path: "/",
      redirect: homeAddressUrl,
      component: () => import("@/layout/Layout.vue"),
      meta: {
        isAuthRequired: true,
      },
      children: [
        {
          path: "/Dashboard/:id",
          name: "Dashboard",
          component: () => import("@/views/Dashboard.vue"),
          meta: {
            isDashboardPage: true,
          },
        },
        {
          path: "/Reporting/:id",
          name: "Reporting",
          component: () => import("@/views/Reporting.vue"),
          meta: {
            isReportingPage: true,
          },
        },
        {
          path: "/set/new/:key",
          name: "new",
          props: (route) => ({ pageLayoutId: route.query.pageLayoutId }),
          component: () => import("@/views/Set/New.vue"),
          meta: {
            isSetPage: true,
            isNewPage: true,
          },
        },

        {
          path: "/set/list/:key",
          name: "list",
          component: () => import("@/views/Set/List.vue"),
          props: (route) => ({
            filterId: route.query.filterId,
            isAdhoc: route.query.isAdhoc,
          }),
          meta: {
            isSetPage: true,
            isListPage: true,
          },
        },
        {
          path: "/set/edit/:key/:id",
          name: "edit",
          component: () => import("@/views/Set/Edit.vue"),
          props: (route) => ({
            id: route.params.id,
            pageLayoutId: route.query.pageLayoutId,
          }),
          meta: {
            isSetPage: true,
            isEditPage: true,
          },
        },
        {
          path: "/set/detail/:key/:id",
          name: "detail",
          component: () => import("@/views/Set/Detail.vue"),
          props: (route) => ({
            id: route.params.id,
            pageLayoutId: route.query.pageLayoutId,
          }),
          meta: {
            isSetPage: true,
            isDetailPage: true,
          },
        },
        {
          path: "/set/calendar/:key/:calendarId",
          name: "calendar",
          component: () => import("@/views/Set/Calendar.vue"),
          props: (route) => ({
            filterId: route.query.filterId,
          }),
          meta: {
            isSetPage: true,
            isCalendarPage: true,
          },
        },
        {
          path: "/set/history/:key/:id",
          name: "history",
          props: (route) => ({ pageLayoutId: route.query.pageLayoutId }),
          component: () => import("@/views/Set/History.vue"),
          meta: {
            isSetPage: true,
            isHistoryPage: true,
          },
        },
        {
          path: "/set/kanban/:key/:id",
          name: "kanban",
          component: () => import("@/views/Set/Kanban.vue"),
          meta: {
            isSetPage: true,
            isKanbanPage: true,
          },
        },
        {
          path: "/set/map/:key/:id",
          name: "map",
          component: () => import("@/views/Set/Map.vue"),
          meta: {
            isSetPage: true,
            isMapPage: true,
          },
        },
        {
          path: "/set/tree/:key/:treeId",
          name: "tree",
          component: () => import("@/views/Set/Tree.vue"),
          props: (route) => ({
            filterId: route.query.filterId,
          }),
          meta: {
            isSetPage: true,
            isTreePage: true,
          },
        },
        {
          path: "/set/content/:key",
          name: "customPage",
          component: () => import("@/views/Set/CustomPage.vue"),
          meta: {
            isSetPage: true,
            isCustomPage: true,
          },
        },
        {
          path: "/set/password-change/:key",
          name: "passwordChangeForLoggedUser",
          component: () =>
            import(
              "@/views/crafted/authentication/PasswordChangeForLoggedUser.vue"
            ),
          meta: {
            isSetPage: true,
          },
        },
        {
          path: "/document/list",
          name: "documents",
          component: () => import("@/views/Document/List.vue"),
          meta: {
            isDocumentPage: true,
          },
        },
        {
          path: "/Account/Settings",
          name: "AccountSettings",
          component: () => import("@/views/crafted/account/Settings.vue"),
        },
        {
          path: "/Account/Notifications",
          name: "AccountNotifications",
          component: () => import("@/views/crafted/account/Notifications.vue"),
        },
        {
          path: "/set/help/:key/permission-none",
          name: "PermissionNone",
          component: () => import("@/views/crafted/errors/PermissionNone.vue"),
          meta: {
            isHelpPage: true,
          },
        },
        {
          path: "/set/help/:key/page-layout-none",
          name: "PageLayoutNone",
          component: () => import("@/views/crafted/errors/PageLayoutNone.vue"),
          meta: {
            isHelpPage: true,
          },
        },
        {
          path: "/set/help/:key/custom-page-none",
          name: "CustomPageNone",
          component: () => import("@/views/crafted/errors/CustomPageNone.vue"),
          meta: {
            isHelpPage: true,
          },
        },
        {
          path: "/set/help/:key/edit-blocked",
          name: "EditBlock",
          component: () => import("@/views/crafted/errors/EditBlock.vue"),
          meta: {
            isHelpPage: true,
          },
        },
        {
          path: "/set/help/:key/detail-blocked",
          name: "DetailBlock",
          component: () => import("@/views/crafted/errors/DetailBlock.vue"),
          meta: {
            isHelpPage: true,
          },
        },
        {
          path: "/set/help/:key/custom-object-filter-none",
          name: "CustomObjectFilterNotFound",
          component: () =>
            import("@/views/crafted/errors/CustomObjectFilterNotFound.vue"),
          meta: {
            isHelpPage: true,
          },
        },
        {
          path: "/set/help/:key/page-under-construction",
          name: "PageUnderConstruction",
          component: () =>
            import("@/views/crafted/errors/PageUnderConstruction.vue"),
          meta: {
            isHelpPage: true,
          },
        },
        {
          path: "/set/help/:key/lost-wave",
          name: "LostWave",
          component: () => import("@/views/crafted/errors/LostWave.vue"),
          meta: {
            isHelpPage: true,
          },
        },
        {
          path: "/set/help/:key/internal-error",
          name: "InternalError",
          component: () => import("@/views/crafted/errors/InternalError.vue"),
          meta: {
            isHelpPage: true,
          },
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/layout/authentication/Auth.vue"),
      children: [
        {
          path: "/sign-in",
          name: "sign-in",
          component: () => import("@/views/crafted/authentication/SignIn.vue"),
          meta: {
            isAuthRequired: false,
            isMembershipPages: true,
            isSignInPage: true,
            backgroundImage: null,
          },
        },
        {
          path: "/Authenticate",
          name: "authenticate",
          props: (route) => ({
            authToken: route.query.authToken,
            isOk: route.query.isOk,
          }),
          component: () =>
            import("@/views/crafted/authentication/Authenticate.vue"),
          meta: {
            isAuthRequired: false,
            isMembershipPages: true,
            isAuthenticatePage: true,
            backgroundImage: "/extras/media/auth/sign-up.svg",
          },
        },
        // {
        //   path: "/sign-up",
        //   name: "sign-up",
        //   component: () => import("@/views/crafted/authentication/SignUp.vue"),
        //   meta: {
        //     isAuthRequired: false,
        //     isMembershipPages: true,
        //     isSignUpPage: true,
        //     backgroundImage: "/extras/media/auth/sign-up.svg",
        //   },
        // },
        {
          path: "/password-reset",
          name: "password-reset",
          component: () =>
            import("@/views/crafted/authentication/PasswordReset.vue"),
          meta: {
            isAuthRequired: false,
            isMembershipPages: true,
            isPasswordResetPage: true,
            backgroundImage: "/extras/media/auth/password-reset.svg",
          },
        },
        {
          path: "/password-change",
          name: "password-change",
          props: (route) => ({ token: route.query.token }),
          component: () =>
            import("@/views/crafted/authentication/PasswordChange.vue"),
          meta: {
            isAuthRequired: false,
            isMembershipPages: true,
            isPasswordChangePage: true,
            backgroundImage: "/extras/media/auth/password-reset.svg",
          },
        },
      ],
    },
    {
      path: "/maintenance",
      name: "coming-soon",
      component: () => import("@/views/crafted/errors/ComingSoon.vue"),
      meta: {
        isAuthRequired: false,
        isComingSoonPage: true,
        isHelpPage: true,
      },
    },
    {
      path: "/login-detect",
      name: "login-detect",
      props: (route) => ({ redirectUrl: route.query.redirectUrl }),
      component: () => import("@/views/crafted/errors/LoginDetect.vue"),
      meta: {
        isAuthRequired: false,
        isHelpPage: true,
        isLoginDetectPage: true,
      },
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      meta: {
        isAuthRequired: true,
        isHelpPage: true,
      },
      component: () => import("@/views/crafted/errors/404.vue"),
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/404",
      meta: {
        isAuthRequired: true,
        isHelpPage: true,
      },
    },
  ];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// router.onError((error) => {
//   if (/loading chunk \d* failed./i.test(error.message)) {
//     window.location.reload();
//   }
// });

router.beforeEach((to, _, next) => {
  // reset config to initial state
  // store.commit(Mutations.RESET_LAYOUT_CONFIG);

  const _isAuthenticated = store.getters._isAuthenticated,
    isAuthRequired = to.meta.isAuthRequired,
    toHref = to["href"],
    currentHref = _["href"],
    noRedirectParameter =
      to.meta.isHelpPage || to.fullPath.includes(homeAddressUrl),
    query = noRedirectParameter
      ? {}
      : {
          redirect: to.fullPath,
        },
    isMaintenanceMode = localStorage.getItem("isMaintenanceMode") || false;

  if (isMaintenanceMode && !to.meta.isComingSoonPage) {
    next({
      name: "coming-soon",
    });
    return;
  }

  if (isAuthRequired && !_isAuthenticated)
    next({
      name: "sign-in",
      query: query,
    });

  if (
    _isAuthenticated &&
    (to.name == "sign-in" ||
      to.name == "sign-up" ||
      to.name == "password-reset" ||
      to.name == "password-change" ||
      to.name == "login-detect" ||
      to.name == "authenticate")
  )
    next({
      path: homeAddressUrl,
    });

  if (isAuthRequired) {
    if (_isAuthenticated) next();
    else
      next({
        name: "sign-in",
        query: query,
      });
  } else {
    next();
  }

  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

router.afterEach((to, from, failure) => {
  if (failure) {
    //
  }
});

export default router;
