<template>
  <div
    v-if="messages.length"
    :class="`${
      !isModal && !this.$route.meta.isListPage ? 'container-fluid' : ''
    } ${classes}`"
  >
    <div
      v-if="messages.length > 0"
      class="alert mt-2 mb-2"
      :class="`alert-${type}`"
      role="alert"
    >
      <ul class="mb-0 list-unstyled">
        <li class="list-unstyled" v-for="message in messages" :key="message">
          {{ message }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "AlertBox",
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
    type: String,
    messages: Array,
    classes: String,
  },
};
</script>
