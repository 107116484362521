<template>
  <Select2
    :placeholder="placeholder"
    ref="select2"
    :id="selectId"
    :name="selectId"
    :settings="settings"
    @select="selection($event)"
  />
</template>
<script>
import Select2 from "vue3-select2-component";
import $ from "jquery";
export default {
  name: "SelectList",
  props: ["template", "value", "valueId", "rowData"],
  components: {
    Select2,
  },
  data() {
    return {
      selectId: `${this.template.id}`,
      placeholder: this.template.field.fieldType.includes("Formula")
        ? this.$t(
            "BaseModelFields.FieldIsCalcForSystem",
            {},
            { locale: this.$store.state.activeLang }
          )
        : this.$t(
            "BaseModelFields.Choose",
            {},
            { locale: this.$store.state.activeLang }
          ),
      selectedText: "",
      selectedId: "",
      settings: {
        dropdownParent: null,
        language: this.template.parent.$root.getSelect2Locale(
          this.$store.state.activeLang
        ),
        theme: "bootstrap-5",
        allowClear: true,
        // closeOnSelect: this.template.field.isMultipleValue ? false : true,
        closeOnSelect: true,
        maximumSelectionSize: 99,
        minimumInputLength: 0,
        dropdownAutoWidth: false,
        disabled:
          this.template.field.disabled ||
          !this.template.field.isActive ||
          this.template.field.fieldType.includes("Formula") ||
          (this.rowData[this.template.parent.primaryKey] &&
            !this.template.field.isEditable),
        multiple: this.template.field.isMultipleValue,
        separator: this.$systemSeparator,
        ajax: this.template.parent.$root.getSelect2AjaxConfiguration(
          this.template.field,
          String.format("rws-FieldItems?id={0}", this.template.field.publicId)
        ),
      },
    };
  },
  computed: {
    currentSelf() {
      return this.template.child ? this.template.child : this.template.parent;
    },
  },
  methods: {
    selection() {
      var el = $(String.format("#{0}", this.selectId)),
        elData = el.select2("data");

      this.selectedText = elData.map((u) => u.text).join(this.$systemSeparator);
      this.selectedId = elData.map((u) => u.id).join(this.$systemSeparator);

      this.currentSelf.editCellTemplateValueChangedForSelect2Components(
        this.template.field,
        this.selectId,
        this.selectedText,
        this.selectedId,
        this.template
      );

      this.template.parent.$root.selectPagedSelectlistSelectionForGrid(
        this.template.field,
        this.selectedId,
        this.currentSelf.pageAllFields,
        this.currentSelf.gridId,
        this.template
      );

      this.template.parent.$root.calculateTriggerFieldForGrid(
        this.currentSelf,
        this.template
      );
    },
  },
  mounted() {
    if (String.isGuid(this.value) && String.isGuid(this.valueId)) return;

    var select = $("#" + this.selectId);
    if (!String.isNullOrWhiteSpace(this.template.field.controllingPublicId)) {
      this.template.parent.$root.select2PagedSelectlistDependencyChildTriggerForGrid(
        select.closest("tr"),
        this.template.field,
        this.template
      );
    }

    if (
      !String.isNullOrWhiteSpace(this.value) &&
      !String.isNullOrWhiteSpace(this.valueId)
    ) {
      if (!this.valueId.includes("error") && !this.value.includes("error")) {
        this.selectedText = this.value;
        this.selectedId = this.valueId;
        this.template.parent.$root.select2SetValue(
          select,
          this.template.field,
          this.valueId,
          this.value,
          true
        );
      }
    }
  },
};
</script>
