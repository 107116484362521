import { appAxios } from "@/core/axios/appAxios";
import { prodAppAxios } from "@/core/axios/prodAppAxios";
import { brsAxios } from "@/core/axios/brsAxios";
import { authAxios } from "@/core/axios/authAxios";

import moment from "moment/moment";
import { createToast } from "mosha-vue-toastify";

var refreshTokenRequestSent = false,
  selectListRequestUrls = [
    "rws-LookupFieldValues",
    "rws-OrganizationalUnitItems",
    "rws-PredefinedItems",
    "rws-FieldItems",
    "rws-PredefinedDependencyItems",
    "rws-DependencyItems",
  ];

function isTokenExpired(config, store) {
  var currentDate = moment(new Date()).format(),
    tokenExpirationDate = moment(
      // "2023-07-06T14:50:15Z",
      localStorage.getItem("tokenExpirationDate"),
      "YYYY-MM-DDTHH:mm:ss"
    ).format();

  return currentDate >= tokenExpirationDate;
}

async function refreshToken(store, router) {
  refreshTokenRequestSent = true;
  await authAxios
    .post("/RefreshToken", {
      accessToken: localStorage.getItem("token"),
      refreshToken: localStorage.getItem("refreshToken"),
      authRequestFromTypeId: process.env.VUE_APP_SIGNIN_AUTHENTICATION_TYPE_ID,
    })
    .then((response) => {
      var result = response.data;
      if (result.isOk) {
        store.commit("setToken", {
          accessToken: result.accessToken,
          refreshToken: result.refreshToken,
          accessTokenExpiration: result.accessTokenExpiration,
        });
        setTimeout(() => {
          refreshCurrentUser(store, router);
        });
      } else {
        let path = router.currentRoute.value.fullPath,
          query = {};
        if (!router.currentRoute.value.meta.isHelpPage) {
          query = {
            redirect: path,
          };
        }
        router.push({
          path: "/sign-in",
          query: query,
        });
        store.commit("logOutUser");
      }
    })
    .finally(() => {
      refreshTokenRequestSent = false;
    })
    .catch((err) => {
      router.push({
        path: "/sign-in",
      });
      store.commit("logOutUser");
    });
}

function refreshCurrentUser(store, router) {
  appAxios.get("/rws-GetCurrentUser").then((response) => {
    var result = response.data;
    if (result.isOk) {
      var item = result.item;

      store.commit("setUserInfo", item);
    } else {
      let path = router.currentRoute.value.fullPath,
        query = {};
      if (!router.currentRoute.value.meta.isHelpPage) {
        query = {
          redirect: path,
        };
      }
      router.push({
        path: "/sign-in",
        query: query,
      });
      store.commit("logOutUser");
    }
  });
}

//configuration axios instances
const setup = (store, router) => {
  //use request
  authAxios.interceptors.request.use(
    (config) => {
      if (Object.readCookie("CurrentLocation")) {
        config.headers["X-Current-Location"] =
          Object.readCookie("CurrentLocation");
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  appAxios.interceptors.request.use(
    async (config) => {
      var tokenExpired = isTokenExpired(config, store);
      if (refreshTokenRequestSent || tokenExpired) {
        //refreshing token
        if (!refreshTokenRequestSent && tokenExpired) {
          refreshToken(store, router);
        }

        return Promise.reject({ config: config, _retryRequest: true });
      }

      if (!tokenExpired) {
        if (Object.readCookie("CurrentLocation")) {
          config.headers["X-Current-Location"] =
            Object.readCookie("CurrentLocation");
        }

        config.headers["Authorization"] = `Bearer ${localStorage.getItem(
          "token"
        )}`;
      }

      if (String.isNullOrWhiteSpace(localStorage.getItem("token"))) {
        return Promise.reject({ config: config, loginRedirect: true });
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  prodAppAxios.interceptors.request.use(
    (config) => {
      if (localStorage.getItem("token")) {
        config.headers["Authorization"] = `Bearer ${localStorage.getItem(
          "token"
        )}`;
      }

      if (Object.readCookie("CurrentLocation")) {
        config.headers["X-Current-Location"] =
          Object.readCookie("CurrentLocation");
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  brsAxios.interceptors.request.use(
    (config) => {
      if (localStorage.getItem("token")) {
        config.headers["Authorization"] = `Bearer ${localStorage.getItem(
          "token"
        )}`;
      }

      if (Object.readCookie("CurrentLocation")) {
        config.headers["X-Current-Location"] =
          Object.readCookie("CurrentLocation");
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  //use response
  appAxios.interceptors.response.use(
    (response) => {
      if (!response.data.isOk) {
        var responseMessage = response.data.msg;
        if (selectListRequestUrls.includes(response.config.url.split("?")[0])) {
          if (!String.isNullOrWhiteSpace(responseMessage)) {
            createToast(responseMessage, {
              showIcon: true,
              position: "top-right",
              type: "danger",
              transition: "zoom",
            });
          }
          return response;
        }

        if (
          responseMessage ===
            process.env.VUE_APP_SYSTEM_CUSTOM_OBJECT_NOT_VALID_KEY ||
          responseMessage ===
            process.env.VUE_APP_SYSTEM_CUSTOM_OBJECT_COULD_NOT_GET_RECORD_KEY ||
          responseMessage ===
            process.env.VUE_APP_SYSTEM_CUSTOM_OBJECT_DATA_NOT_FOUND_KEY ||
          responseMessage ===
            process.env.VUE_APP_SYSTEM_USER_DATA_IS_NOT_FOUND_KEY ||
          responseMessage ===
            process.env.VUE_APP_SYSTEM_CUSTOM_OBJECT_NOT_FOUND_KEY ||
          responseMessage ===
            process.env.VUE_APP_SYSTEM_CUSTOM_OBJECT_IS_PASSIVE_KEY
        ) {
          router.push({
            path: process.env.VUE_APP_HOME_ADDRESS_URL,
          });
        } else if (
          responseMessage === process.env.VUE_APP_SYSTEM_PERMISSION_NONE_KEY ||
          responseMessage ===
            process.env.VUE_APP_SYSTEM_PAGELAYOUT_NOTVALID_OR_PERMITTED_KEY ||
          responseMessage ===
            process.env.VUE_APP_SYSTEM_PAGELAYOUT_NO_PERMITTED_KEY
        ) {
          if (!response.config.url.includes("RelationalRecords")) {
            router.push({
              name: "PermissionNone",
              params: { key: router.currentRoute._value.params.key },
            });
          }
        } else if (
          responseMessage === process.env.VUE_APP_SYSTEM_PAGELAYOUT_NONE_KEY ||
          responseMessage ===
            process.env.VUE_APP_SYSTEM_PAGELAYOUT_ITEM_NOT_FOUND_KEY
        ) {
          router.push({
            name: "PageLayoutNone",
            params: { key: router.currentRoute._value.params.key },
          });
        } else if (
          responseMessage ===
          process.env.VUE_APP_SYSTEM_LIST_PAGE_PROJECT_DB_ERROR_KEY
        ) {
          router.push({
            name: "LostWave",
            params: { key: router.currentRoute._value.params.key },
          });
        } else if (
          responseMessage === process.env.VUE_APP_SYSTEM_CUSTOMPAGE_NONE_KEY
        ) {
          router.push({
            name: "CustomPageNone",
            params: { key: router.currentRoute._value.params.key },
          });
        } else if (
          responseMessage ===
          process.env.VUE_APP_SYSTEM_CUSTOM_OBJECT_DOES_NOT_HAVE_VIEW_FILTER_KEY
        ) {
          router.push({
            name: "CustomObjectFilterNotFound",
            params: { key: router.currentRoute._value.params.key },
          });
        } else if (
          responseMessage === process.env.VUE_APP_SYSTEM_RECORD_NOT_FOUND_KEY
        ) {
          router.push({
            name: "list",
            params: { key: router.currentRoute._value.params.key },
          });
        } else if (
          !String.isNullOrWhiteSpace(responseMessage) &&
          responseMessage.includes(
            process.env.VUE_APP_SYSTEM_INTERNAL_ERROR_KEY
          )
        ) {
          store.commit("setInternalErrorDescription", responseMessage);
          router.push({
            name: "InternalError",
            params: { key: router.currentRoute._value.params.key },
          });
        }
      }
      return response;
    },
    async (err) => {
      var config = err.config,
        response = err.response;

      if (err._retryRequest) {
        const delayRetryRequest = new Promise((resolve) => {
          setTimeout(() => {
            // console.log("retry the request", config.url);
            resolve();
          }, 4000);
        });
        return delayRetryRequest.then(() => appAxios(config));
      }

      if (err.loginRedirect) {
        if (router.currentRoute.value.meta.isSignInPage) {
          return Promise.reject(err);
        }

        let path = router.currentRoute.value.fullPath,
          query = {};
        if (!router.currentRoute.value.meta.isHelpPage) {
          query = { redirect: path };
        }
        router.push({
          path: "/sign-in",
          query: query,
        });
        store.commit("logOutUser");
        return Promise.reject(err);
      }

      if (response) {
        if (response.status === 401) {
          let path = router.currentRoute.value.fullPath,
            query = {};
          if (!router.currentRoute.value.meta.isHelpPage) {
            query = { redirect: path };
          }
          router.push({
            path: "/sign-in",
            query: query,
          });
          store.commit("logOutUser");
        } else if (
          response.status === 404 &&
          !response.config.url.includes(process.env.VUE_APP_SOCKET_API_URL)
        ) {
          router.push({
            path: process.env.VUE_APP_HOME_ADDRESS_URL,
          });
        } else if (response.status === 555) {
          var responseMessage = response.data.msg;
          if (selectListRequestUrls.includes(config.url.split("?")[0])) {
            if (!String.isNullOrWhiteSpace(responseMessage)) {
              createToast(responseMessage, {
                showIcon: true,
                position: "top-right",
                type: "danger",
                transition: "zoom",
              });
            }
          } else if (
            !String.isNullOrWhiteSpace(responseMessage) &&
            responseMessage.includes(
              process.env.VUE_APP_SYSTEM_INTERNAL_ERROR_KEY
            )
          ) {
            var key = router.currentRoute._value.params.key;
            store.commit("setInternalErrorDescription", responseMessage);
            router.push({
              name: "InternalError",
              params: {
                key: String.isNullOrWhiteSpace(key)
                  ? process.env.VUE_APP_SHORT_NAME.toLowerCase()
                  : key,
              },
            });
          }
        }
        return Promise.reject(err);
      }
    }
  );

  prodAppAxios.interceptors.response.use(
    (response) => {
      if (!response.data.isOk) {
        var responseMessage = response.data.msg;
        if (
          responseMessage ===
            process.env.VUE_APP_SYSTEM_CUSTOM_OBJECT_NOT_VALID_KEY ||
          responseMessage ===
            process.env.VUE_APP_SYSTEM_CUSTOM_OBJECT_COULD_NOT_GET_RECORD_KEY ||
          responseMessage ===
            process.env.VUE_APP_SYSTEM_CUSTOM_OBJECT_DATA_NOT_FOUND_KEY ||
          responseMessage ===
            process.env.VUE_APP_SYSTEM_USER_DATA_IS_NOT_FOUND_KEY ||
          responseMessage ===
            process.env.VUE_APP_SYSTEM_CUSTOM_OBJECT_NOT_FOUND_KEY ||
          responseMessage ===
            process.env.VUE_APP_SYSTEM_CUSTOM_OBJECT_IS_PASSIVE_KEY
        ) {
          router.push({
            path: process.env.VUE_APP_HOME_ADDRESS_URL,
          });
        } else if (
          responseMessage === process.env.VUE_APP_SYSTEM_PERMISSION_NONE_KEY ||
          responseMessage ===
            process.env.VUE_APP_SYSTEM_PAGELAYOUT_NOTVALID_OR_PERMITTED_KEY ||
          responseMessage ===
            process.env.VUE_APP_SYSTEM_PAGELAYOUT_NO_PERMITTED_KEY
        ) {
          router.push({
            name: "PermissionNone",
            params: { key: router.currentRoute._value.params.key },
          });
        } else if (
          responseMessage === process.env.VUE_APP_SYSTEM_PAGELAYOUT_NONE_KEY ||
          responseMessage ===
            process.env.VUE_APP_SYSTEM_PAGELAYOUT_ITEM_NOT_FOUND_KEY
        ) {
          router.push({
            name: "PageLayoutNone",
            params: { key: router.currentRoute._value.params.key },
          });
        } else if (
          responseMessage ===
          process.env.VUE_APP_SYSTEM_LIST_PAGE_PROJECT_DB_ERROR_KEY
        ) {
          router.push({
            name: "LostWave",
            params: { key: router.currentRoute._value.params.key },
          });
        } else if (
          responseMessage ===
          process.env.VUE_APP_SYSTEM_CUSTOM_OBJECT_DOES_NOT_HAVE_VIEW_FILTER_KEY
        ) {
          router.push({
            name: "CustomObjectFilterNotFound",
            params: { key: router.currentRoute._value.params.key },
          });
        }
      }
      return response;
    },
    async (error) => {
      let path = router.currentRoute.value.fullPath,
        query = {};
      if (!router.currentRoute.value.meta.isHelpPage) {
        query = { redirect: path };
      }
      router.push({
        path: "/sign-in",
        query: query,
      });
      store.commit("logOutUser");
      return Promise.reject(error);
    }
  );

  brsAxios.interceptors.response.use(
    (response) => {
      if (!response.data.isOk) {
        var responseMessage = response.data.msg;
        if (
          responseMessage ===
            process.env.VUE_APP_SYSTEM_CUSTOM_OBJECT_NOT_VALID_KEY ||
          responseMessage ===
            process.env.VUE_APP_SYSTEM_CUSTOM_OBJECT_COULD_NOT_GET_RECORD_KEY ||
          responseMessage ===
            process.env.VUE_APP_SYSTEM_CUSTOM_OBJECT_DATA_NOT_FOUND_KEY ||
          responseMessage ===
            process.env.VUE_APP_SYSTEM_USER_DATA_IS_NOT_FOUND_KEY ||
          responseMessage ===
            process.env.VUE_APP_SYSTEM_CUSTOM_OBJECT_NOT_FOUND_KEY ||
          responseMessage ===
            process.env.VUE_APP_SYSTEM_CUSTOM_OBJECT_IS_PASSIVE_KEY
        ) {
          router.push({
            path: process.env.VUE_APP_HOME_ADDRESS_URL,
          });
        } else if (
          responseMessage === process.env.VUE_APP_SYSTEM_PERMISSION_NONE_KEY ||
          responseMessage ===
            process.env.VUE_APP_SYSTEM_PAGELAYOUT_NOTVALID_OR_PERMITTED_KEY
        ) {
          router.push({
            name: "PermissionNone",
            params: { key: router.currentRoute._value.params.key },
          });
        } else if (
          responseMessage === process.env.VUE_APP_SYSTEM_PAGELAYOUT_NONE_KEY ||
          responseMessage ===
            process.env.VUE_APP_SYSTEM_PAGELAYOUT_ITEM_NOT_FOUND_KEY
        ) {
          router.push({
            name: "PageLayoutNone",
            params: { key: router.currentRoute._value.params.key },
          });
        } else if (
          responseMessage ===
          process.env.VUE_APP_SYSTEM_LIST_PAGE_PROJECT_DB_ERROR_KEY
        ) {
          router.push({
            name: "LostWave",
            params: { key: router.currentRoute._value.params.key },
          });
        } else if (
          responseMessage ===
          process.env.VUE_APP_SYSTEM_CUSTOM_OBJECT_DOES_NOT_HAVE_VIEW_FILTER_KEY
        ) {
          router.push({
            name: "CustomObjectFilterNotFound",
            params: { key: router.currentRoute._value.params.key },
          });
        }
      }
      return response;
    },
    async (error) => {
      let path = router.currentRoute.value.fullPath,
        query = {};
      if (!router.currentRoute.value.meta.isHelpPage) {
        query = { redirect: path };
      }
      router.push({
        path: "/sign-in",
        query: query,
      });
      store.commit("logOutUser");
      return Promise.reject(error);
    }
  );
};

export default setup;
