<template>
  <div
    class="position-relative view-edit-mode"
    :class="{ hidden: isDetailPage }"
  >
    <input
      ref="textBox"
      class="form-control"
      v-model="textBoxValue"
      :spellcheck="$isTextSpellCheck"
      :placeholder="placeholder"
      :id="element.id"
      :name="element.id"
      :disabled="isDisabled"
      :required="isRequired"
      :autocomplete="$isAutoComplete"
      @change="textBoxChange"
      @keyup="textBoxKeyup"
      :class="elementClasses"
    />
    <div class="invalid-tooltip" v-if="field.fieldType.includes('Url')">
      {{
        this.$t(
          "FieldWarnings.UrlNotValid",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}
    </div>
    <div class="invalid-tooltip" v-else-if="field.fieldType.includes('Email')">
      {{
        this.$t(
          "FieldWarnings.EmailNotValid",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}
    </div>
    <div class="invalid-tooltip" v-else>
      {{
        this.$t(
          "FieldWarnings.TextNotValid",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}
    </div>
  </div>
  <div class="view-detail-mode" v-if="isDetailPage">
    <div class="d-flex text-break" :class="elementClasses">
      <span
        v-if="
          field.fieldType.includes('Url') &&
          textBoxValue !== this.$valueNotAvailableSeparator
        "
      >
        <a :href="textBoxValue" target="_blank">{{
          String.isNullOrWhiteSpace(textBoxValue)
            ? this.$valueNotAvailableSeparator
            : textBoxValue
        }}</a></span
      >
      <span
        v-else-if="
          field.fieldType.includes('Email') &&
          textBoxValue !== this.$valueNotAvailableSeparator
        "
      >
        <a :href="String.format('mailto:{0}', textBoxValue)">{{
          String.isNullOrWhiteSpace(textBoxValue)
            ? this.$valueNotAvailableSeparator
            : textBoxValue
        }}</a></span
      >
      <span :id="element.id" v-else>{{
        String.isNullOrWhiteSpace(textBoxValue)
          ? this.$valueNotAvailableSeparator
          : textBoxValue
      }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "TextBox",
  props: ["element", "field", "value", "crudType", "isCellEdit", "rowData"],
  data() {
    return {
      isDetailPage: this.crudType === "DETAIL",
      isNewPage: this.crudType === "NEW",
      textBoxValue: this.value,
      elementClasses: !String.isNullOrWhiteSpace(this.element.valueClassNames)
        ? this.element.valueClassNames
        : "",
      placeholder:
        this.field.fieldType.includes("Formula") ||
        this.field.fieldType.includes("AutoId")
          ? this.$t(
              "BaseModelFields.FieldIsCalcForSystem",
              {},
              { locale: this.$store.state.activeLang }
            )
          : "",
      isRequired:
        (this.field.isRequired || this.element.isRequired) &&
        this.field.fieldType !== "AutoId" &&
        !this.field.fieldType.includes("Formula"),
      isDisabled:
        !this.field.isActive ||
        this.element.isDisabled ||
        this.field.fieldType.includes("Formula") ||
        this.field.fieldType.includes("AutoId") ||
        (this.$route.meta.isEditPage && !this.field.isEditable),
      oldValue: "",
      isPasswordInput:
        this.$route.params.key == "user" &&
        this.field.formulaName == "PASSWORD",
    };
  },
  methods: {
    textBoxChange() {
      if (!this.isCellEdit) {
        this.$root.calculateTriggerField(this.field);
      }
    },
    textBoxKeyup() {
      if (this.isPasswordInput) {
        this.textBoxValue = String.replaceAllWhiteSpaceCharacters(
          this.textBoxValue
        );
      }
    },
    setValue(isSuccess, value) {
      this.textBoxValue = value;
      if (isSuccess) {
        this.$refs.textBox.setAttribute("data-oldvalue", value);
        this.oldValue = value;
      }
    },
  },
  mounted() {
    var textBox = this.$refs.textBox,
      type = "text";
    if (this.field.fieldType.includes("Email")) {
      type = "email";
    } else if (this.field.fieldType.includes("Url")) {
      type = "url";
    } else if (this.isPasswordInput) {
      type = "password";
    }
    textBox.setAttribute("type", type);

    if (!this.field.fieldType.includes("Formula")) {
      this.textBoxValue = this.value;
      this.oldValue = this.value;
    }

    if (!this.field.fieldType.includes("Formula"))
      textBox.setAttribute("data-oldvalue", this.oldValue);

    if (
      this.field.fieldType.includes("Email") ||
      this.field.fieldType.includes("Url")
    ) {
      textBox.setAttribute("maxlength", 450);
    } else {
      textBox.setAttribute("maxlength", this.field.length);
    }
  },
};
</script>
