<template>
  <input
    type="hidden"
    :id="String.format('SELECT-TEXT-{0}', element.id)"
    :name="String.format('SELECT-TEXT-{0}', element.id)"
    :value="selectedId ? selectedText : ''"
    :data-oldvalue="oldText"
    v-if="String.isNullOrWhiteSpace(this.field.controllingPublicId)"
  />
  <div class="view-detail-mode" :class="elementClasses" v-if="isDetailPage">
    <div v-if="field.isMultipleValue && !String.isNullOrWhiteSpace(textsOrj)">
      <span
        class="badge text-dark border border-primary me-1 fs-7"
        v-for="(item, i) in valuesOrj"
        :key="item"
      >
        {{ textsOrj[i] }}
      </span>
    </div>
    <span class="d-flex text-break" v-else>{{ values }}</span>
  </div>
  <div
    class="view-edit-mode"
    v-if="String.isNullOrWhiteSpace(this.field.controllingPublicId)"
    :class="{ hidden: isDetailPage }"
  >
    <div
      :class="{
        'select2-invalid': isInvalid,
        'select2-valid': !isInvalid,
      }"
      class="position-relative"
    >
      <Select2
        :placeholder="placeholder"
        ref="select2"
        :id="element.id"
        :name="element.id"
        :settings="settings"
        :class="elementClasses"
        class="select2-parent-container"
        :data-oldvalue="oldValue"
        @select="selection($event)"
        :required="isRequired"
      />
      <div class="invalid-message invalid-tooltip hidden">
        {{
          this.$t(
            "FieldWarnings.TextNotValid",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </div>
    </div>
  </div>
  <DependecyItem
    :element="element"
    :field="field"
    :value="value"
    :valueId="valueId"
    :crudType="crudType"
    :isCellEdit="isCellEdit"
    :rowData="rowData"
    v-else
  />
</template>
<script>
/* Select2 settings api document: https://select2.org/configuration/options-api */
import DependecyItem from "./SelectListDependencyItem.vue";
import Select2 from "vue3-select2-component";
import $ from "jquery";
export default {
  name: "SelectList",
  props: [
    "element",
    "field",
    "value",
    "valueId",
    "crudType",
    "isCellEdit",
    "rowData",
    "cellEditDrowdownParent",
  ],
  components: {
    Select2,
    DependecyItem,
  },
  data() {
    return {
      valuesOrj: [],
      textsOrj: [],
      isNewPage: this.crudType === "NEW",
      isDetailPage: this.crudType === "DETAIL",
      isRequired: this.field.isRequired || this.element.isRequired,
      elementClasses: !String.isNullOrWhiteSpace(this.element.valueClassNames)
        ? this.element.valueClassNames
        : "",
      placeholder: this.field.fieldType.includes("Formula")
        ? this.$t(
            "BaseModelFields.FieldIsCalcForSystem",
            {},
            { locale: this.$store.state.activeLang }
          )
        : this.$t(
            "BaseModelFields.Choose",
            {},
            { locale: this.$store.state.activeLang }
          ),
      selectedText: "",
      selectedId: "",
      values: "",
      oldValue: "",
      oldText: "",
      settings: {
        dropdownParent: !String.isNullOrWhiteSpace(this.cellEditDrowdownParent)
          ? $("#" + this.cellEditDrowdownParent)
          : this.$root.getSelect2ParentDropdown(),
        language: this.$root.getSelect2Locale(this.$store.state.activeLang),
        theme: "bootstrap-5",
        allowClear: !(
          !this.field.isActive ||
          this.element.isDisabled ||
          this.field.fieldType.includes("Formula") ||
          (this.$route.meta.isEditPage && !this.field.isEditable)
        ),
        // closeOnSelect: this.field.isMultipleValue ? false : true,
        closeOnSelect: true,
        maximumSelectionSize: 99,
        minimumInputLength: 0,
        dropdownAutoWidth: false,
        disabled:
          !this.field.isActive ||
          this.element.isDisabled ||
          this.field.fieldType.includes("Formula") ||
          (this.$route.meta.isEditPage && !this.field.isEditable),
        multiple: this.field.isMultipleValue,
        separator: this.$systemSeparator,
        ajax: this.$root.getSelect2AjaxConfiguration(
          this.field,
          String.format("rws-FieldItems?id={0}", this.field.publicId)
        ),
      },
    };
  },
  computed: {
    isInvalid() {
      return (
        (this.field.isRequired || this.element.isRequired) &&
        String.isNullOrWhiteSpace(this.selectedId)
      );
    },
  },
  methods: {
    setValue(isSuccess, value, text) {
      var select = $("#" + this.field.publicId);
      this.selectedId = value;
      this.selectedText = text;
      if (
        !String.isNullOrWhiteSpace(this.selectedId) &&
        !String.isNullOrWhiteSpace(this.selectedText)
      ) {
        this.$root.select2SetValue(
          select,
          this.field,
          this.selectedId,
          this.selectedText
        );
      } else {
        select.val(null);
      }

      if (isSuccess) {
        if (!String.isNullOrWhiteSpace(value)) {
          if (Object.keys(value).length) {
            this.valuesOrj = value.split(this.$systemSeparator);
            this.textsOrj = text.split(this.$systemSeparator);
          }
        } else {
          this.valuesOrj = this.textsOrj = null;
        }

        this.values = !String.isNullOrWhiteSpace(text)
          ? text.split(this.$systemSeparator).join(", ")
          : this.$valueNotAvailableSeparator;

        this.oldValue = value;
        this.oldText = text;
        select.attr(
          "data-oldvalue",
          String.isNullOrWhiteSpace(this.valueId) ? "" : this.valueId
        );
      }
    },
    childTrigger({ field, isCellEdit, childValue, rowData }) {
      // && this.$route.meta.isListPage
      if (isCellEdit) {
        var recordId = rowData.PUBLICID,
          parentInfo = this.$parent.$parent,
          currentPageData = parentInfo.pageData,
          record = parentInfo.pagedItems.find((f) => f.PUBLICID == recordId);
        if (String.isNullOrWhiteSpace(record)) return;

        var allFields = parentInfo.pageAllFields.map(function (m) {
          return {
            publicId: m.publicId,
            fieldModel: {
              ...m,
            },
          };
        });
        // var allFields = currentPageData.filter.visibleFields;
        // if (currentPageData.filter.hiddenFields.length > 0) {
        //   currentPageData.filter.hiddenFields.forEach((element) => {
        //     allFields.push(element);
        //   });
        // }
        var fieldFormulaName = this.$root.getFieldFormulaName(
          allFields,
          field.controllingPublicId
        );
        if (String.isNullOrWhiteSpace(fieldFormulaName)) return;

        this.$root.select2PagedSelectlistDependencyChildTriggerWithCellEdit(
          field,
          allFields.find((f) => f.publicId == field.controllingPublicId)
            .fieldModel,
          record[fieldFormulaName + this.$fieldPublicIdFormulaExtension]
        );
      } else {
        this.$root.select2PagedSelectlistDependencyChildTrigger(field);
      }
    },
    selection($event) {
      /* UI Change Event Fix */
      this.$root.formItemNewChangeEvent([this.field.publicId]);

      var el = $(String.format("#{0}", this.field.publicId)),
        elData = el.select2("data");

      this.selectedText = elData.map((u) => u.text).join(this.$systemSeparator);
      this.selectedId = elData.map((u) => u.id).join(this.$systemSeparator);

      this.$root.select2SetValue(
        el,
        this.field.isMultipleValue,
        this.selectedId,
        this.selectedText
      );

      if (!this.isCellEdit && !this.$route.meta.isDetailPage) {
        this.$root.select2PagedSelectlistSelection(this.field, this.selectedId);
        this.$root.calculateTriggerField(this.field);
      }
    },
  },
  mounted() {
    var select = $("#" + this.field.publicId);
    if (
      !String.isNullOrWhiteSpace(this.value) &&
      !String.isNullOrWhiteSpace(this.valueId)
    ) {
      if (!this.valueId.includes("error") && !this.value.includes("error")) {
        this.selectedText = this.value;
        this.selectedId = this.valueId;
        this.$root.select2SetValue(
          $("#" + this.field.publicId),
          this.field,
          this.valueId,
          this.value,
          this.isCellEdit
        );
        // this.selection();
      }
    }
    this.oldText = String.isNullOrWhiteSpace(this.value) ? "" : this.value;
    this.oldValue = String.isNullOrWhiteSpace(this.valueId) ? "" : this.valueId;
    select.attr("data-oldvalue", this.oldValue);

    if (
      !String.isNullOrWhiteSpace(this.valueId) &&
      !String.isNullOrWhiteSpace(this.value)
    ) {
      if (Object.keys(this.valueId).length) {
        this.valuesOrj = this.valueId.split(this.$systemSeparator);
        this.textsOrj = this.value.split(this.$systemSeparator);
      }
    }

    this.values = !String.isNullOrWhiteSpace(this.value)
      ? this.value.split(this.$systemSeparator).join(", ")
      : this.$valueNotAvailableSeparator;
  },
};
</script>
