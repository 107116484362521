<template>
  <div class="form-check form-switch ms-1">
    <input
      ref="checkbox"
      class="form-check-input"
      type="checkbox"
      :id="template.id"
      :name="template.id"
      :disabled="disabled"
      v-model="checkboxValue"
      @change="checkboxOnChange"
    />
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "Checkbox",
  props: ["template", "value", "disabled", "rowData"],
  data() {
    return {
      checkboxValue: this.value,
    };
  },
  computed: {
    currentSelf() {
      return this.template.child ? this.template.child : this.template.parent;
    },
  },
  methods: {
    checkboxOnChange() {
      this.checkboxValue = $("#" + this.template.id).is(":checked");

      this.currentSelf.editCellTemplateValueChangedForCheckBoxFieldTypes(
        this.template,
        this.checkboxValue
      );

      this.template.parent.$root.calculateTriggerFieldForGrid(
        this.currentSelf,
        this.template
      );
    },
  },
};
</script>
